import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// import Button from '../components/Button/Button.js'
import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import BlogPost from '../components/BlogPost/BlogPost.js'
import BlogSideWidgets from '../components/BlogSideWidgets/BlogSideWidgets.js'
import ListingRow from '../components/ListingRow/ListingRow.js'

import { 
  tryToFetchBlogPosts,
  tryToFetchBlogCategories,
  tryToFetchBlogTags
} from '../actions/actions.export.js'

class BlogRoute extends Component {
  constructor(props){
    super(props)

    this.state = {
      currentTag: false,
      currentCategory: false
    }
  }

  componentDidMount(){
    this.update(this.props);
  }

  componentWillReceiveProps(newprops){
    this.update(newprops);
  }

  update(newprops){
    const { dispatch } = this.props;

    const blog = newprops.blog;

    // console.log(blog.tryingToFetchBlogPosts , blog.tryToFetchBlogCategories , blog.tryingToFetchTags);
    if(blog.tryingToFetchBlogPosts || blog.tryToFetchBlogCategories || blog.tryingToFetchTags) return;

    var search = window.location.search.substring(1);
    let params = {};
    if(search){
      params = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
    }

    if(newprops.match){
      if(newprops.match.params.tagSlug){
        for(let i in blog.tags){
          if(blog.tags[i].slug == newprops.match.params.tagSlug){
            params.tag = blog.tags[i].id;
            this.setState({
              currentTag: blog.tags[i].name
            })
          }
        }
      }
      if(newprops.match.params.categorySlug){
        for(let i in blog.categories){
          if(blog.categories[i].slug == newprops.match.params.categorySlug){
            params.category = blog.categories[i].id;
            this.setState({
              currentCategory: blog.categories[i].name
            })
          }
        }
      }
    }

    if(params.page){
      params.page = parseInt(params.page);
    }

    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    if(queryString !== blog.lastQueryString){
      dispatch(tryToFetchBlogPosts({
        queryParams: params,
        queryString: queryString
      }))
    }
  }

  render(){
    const { dispatch, blog } = this.props;
    const state = this.state;

    let filteringByTag = blog.lastQueryParams.tag ? true : false;
    let filteringByCategory = blog.lastQueryParams.category ? true : false;

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-blog fa-fw secondary-header-icon"></i>&nbsp;
                Blog
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7">
            {
              blog.error && <p className="lead">
                {blog.error}
              </p>
            }


            {
              filteringByTag && <div>              
                <p className="lead no-margin-bottom">
                  Filtering by tag: <span className="blog-post-tag">{this.state.currentTag}</span>
                </p>
                <Link to="/blog" className="blog-post-remove-filter"><i className="fal fa-times"/> remove filter</Link>
                <hr/>
              </div>
            }
            
            {
              filteringByCategory && <div>              
                <p className="lead no-margin-bottom">
                  Filtering by category: <span className="blog-post-category">{this.state.currentCategory}</span>
                </p>
                <Link to="/blog" className="blog-post-remove-filter"><i className="fal fa-times"/> remove filter</Link>
                <hr/>
              </div>
            }

            {
              (!blog.error && blog.pagination.page > 1) && 
              <p className="translucent"> Page { blog.pagination.page } of { blog.pagination.pages } </p>
            }
            
            {
              blog.currentPosts.map((b, i)=>{
                return <BlogPost post={blog.postCache[b]} key={i} excerpt={true}/>
              })
            }
            <div className="translucent">
              Page { blog.pagination.page } of { blog.pagination.pages }
            </div>
            <div className="flex-split">
              {
                blog.pagination.page > 1 ? <Link to={window.location.pathname + "?page=" + (parseInt(blog.pagination.page) - 1) }>Previous Page</Link> : <span/>
              }
              {
                blog.pagination.page < blog.pagination.pages ? <Link to={window.location.pathname + "?page=" + (parseInt(blog.pagination.page) + 1) }>Next Page</Link> : <span/>
              }
            </div>
          </div>
          <div className="col-md-3">
            <BlogSideWidgets/>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    blog
    // ...
  } = state;

  return {
    blog
    // ...
  }
}

export default connect(mapStateToProps)(BlogRoute);