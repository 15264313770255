export const realtor = (state = {
  listingDetailCache: {},
  listings: [],
  myListingIDs: [],
  listingSearchResults: [],
  tryingToGetListings: false,
  tryingToGetListingDetail: false,
}, action) => {

  switch(action.type){
    case 'REQUEST_LISTINGS':

      return {
        ...state,
        tryingToGetListings: true
      }

    case 'RECEIVE_LISTINGS_FAIL':

      return {
        ...state,
        tryingToGetListings: false
      }

    case 'RECEIVE_LISTINGS_SUCCESS':

      var myListingIDs = [];
      var listings = [];
      for(var i in action.data){
        console.log(action.data[i]);
        if(action.data[i].type === "LL" && action.data[i].address === "437 S Ashland Avenue"){
          // skip this double listing
          // console.log(action.data[i]);
        } else {
          myListingIDs.push(action.data[i].id);
          listings.push(action.data[i]);
        }
      }



      return {
        ...state,
        myListingIDs: myListingIDs,
        listings: listings,
        tryingToGetListings: false
      }
    
    case 'REQUEST_LISTING_DETAIL':

      return {
        ...state,
        tryingToGetListingDetail: true
      }

    case 'RECEIVE_LISTING_DETAIL_FAIL':

      return {
        ...state,
        tryingToGetListingDetail: false
      }

    case 'RECEIVE_LISTING_DETAIL_SUCCESS':

      var listingDetailCache = state.listingDetailCache;
      listingDetailCache[action.data.id] = action.data;

      return {
        ...state,
        listingDetailCache: listingDetailCache,
        tryingToGetListingDetail: false
      }


    case 'REQUEST_SEARCH_LISTINGS':

      return {
        ...state,
        tryingToGetSearchListings: true
      }

    case 'RECEIVE_SEARCH_LISTINGS_FAIL':

      return {
        ...state,
        tryingToGetSearchListings: false
      }

    case 'RECEIVE_SEARCH_LISTINGS_SUCCESS':

      return {
        ...state,
        listingSearchResults: action.data.results,
        listingSearchResultsTotal: action.data.totalResults, 
        listingSearchPage: action.data.page,
        listingSearchPages: action.data.pages,
        tryingToGetSearchListings: false
      }
      
    default:
      return state
  }
}