import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux';
import { globalReducer } from './reducers.global.js';
import { tooltip } from './reducers.tooltip.js';
import { realtor } from './reducers.realtor.js';
import { blog } from './reducers.blog.js';

import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  routing,
  globalReducer,
  form: formReducer,
  realtor,
  blog,
  tooltip

  // ...
})

export default rootReducer
