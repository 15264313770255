export const blog = (state = {
  postCache: {},
  currentPosts: [],
  categories: [],
  tags: [],
  tryingToFetchBlogPosts: false,
  lastQueryString: false,
  lastQueryParams: {},
  pagination: {
    page: 0,
    pages: 0,
    totalPosts: 0
  }
}, action) => {

  switch(action.type){
    // ALL POSTS
    case 'REQUEST_BLOG_POSTS':

      return {
        ...state,
        error: undefined,
        postError: undefined,
        tryingToFetchBlogPosts: true
      }

    case 'RECEIVE_BLOG_POSTS_FAIL':

      return {
        ...state,
        currentPosts: [],
        error: action.data.error,
        postError: undefined,
        lastQueryString: action.data.packet ? action.data.packet.queryString : false,
        lastQueryParams: action.data.packet ? action.data.packet.queryParams : {},
        tryingToFetchBlogPosts: false,
        pagination: {
          page: 0,
          pages: 0,
          totalPosts: 0
        }
      }

    case 'RECEIVE_BLOG_POSTS_SUCCESS':

      var postCache = state.postCache;
      var currentPosts = [];
      for(let i in action.data.posts){
        postCache[action.data.posts[i].id] = action.data.posts[i];
        currentPosts.push(action.data.posts[i].id);
      }

      return {
        ...state,
        error: undefined,
        postError: undefined,
        postCache: postCache,
        pagination: action.data.pagination,
        currentPosts: currentPosts,
        lastQueryString: action.data.queryString,
        lastQueryParams: action.data.queryParams,
        tryingToFetchBlogPosts: false
      }
    
    // SINGLE POST
    case 'REQUEST_BLOG_POST':

      return {
        ...state,
        postError: undefined,
        tryingToFetchBlogPost: true
      }

    case 'RECEIVE_BLOG_POST_FAIL':

      return {
        ...state,
        postError: action.data.error,
        tryingToFetchBlogPost: false,
        lastQueryString: "",
        lastQueryParams: {},
      }

    case 'RECEIVE_BLOG_POST_SUCCESS':

      var postCache = state.postCache;
      postCache[action.data.id] = action.data;
      var currentPosts = [action.data.id];

      return {
        ...state,
        postCache: postCache,
        postError: undefined,
        currentPosts: currentPosts,
        lastQueryString: false,
        lastQueryParams: {},
        currentCategory: false,
        currentTag: false,
        tryingToFetchBlogPost: false
      }
    

    // CATEGORIES
    case 'REQUEST_BLOG_CATEGORIES':

      return {
        ...state,
        tryingToFetchCategories: true
      }

    case 'RECEIVE_BLOG_CATEGORIES_FAIL':

      return {
        ...state,
        tryingToFetchCategories: false
      }

    case 'RECEIVE_BLOG_CATEGORIES_SUCCESS':

      for(var i in action.data){
        if(action.data[i].count === 0){
          action.data.splice(i, 1);
          i--;
        }
      }
    
      return {
        ...state,
        categories: action.data,
        tryingToFetchCategories: false
      }
    
    // TAGS
    case 'REQUEST_BLOG_TAGS':

      return {
        ...state,
        tryingToFetchTags: true
      }

    case 'RECEIVE_BLOG_TAGS_FAIL':

      return {
        ...state,
        tryingToFetchTags: false
      }

    case 'RECEIVE_BLOG_TAGS_SUCCESS':
    
      for(var i in action.data){
        if(action.data[i].count === 0){
          action.data.splice(i, 1);
          i--;
        }
      }
      return {
        ...state,
        tags: action.data,
        tryingToFetchTags: false
      }    
      

    // FEATURED MEDIA
    case 'REQUEST_FEATURED_MEDIA':

      return {
        ...state,
        tryingToFetchFeaturedMedia: true
      }

    case 'RECEIVE_FEATURED_MEDIA_FAIL':

      return {
        ...state,
        tryingToFetchFeaturedMedia: false
      }

    case 'RECEIVE_FEATURED_MEDIA_SUCCESS':
    
      var postCache = state.postCache;
      postCache[action.data.id] = action.data;

      return {
        ...state,
        postCache: postCache,
        tryingToFetchFeaturedMedia: false
      }    
      
    default:
      return state
  }
}