import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ListingRow from '../components/ListingRow/ListingRow.js'
import ListingDetail from '../components/ListingDetail/ListingDetail.js'

import { tryToFetchListingDetail } from '../actions/actions.export.js'

class ListingDetailRoute extends Component {
  constructor(props){
    super(props)

    this.state = {
      id: "",
      type: ""
    }
  }

  componentDidMount(){
    const { realtor, dispatch } = this.props;
    
    if(!realtor.listingDetailCache[this.props.match.params.id]){
      dispatch(tryToFetchListingDetail({
        id: this.props.match.params.id,
        type: this.props.match.params.type
      }))
    } else {
      this.setState({
        id: this.props.match.params.id,
        type: this.props.match.params.type
      })
    }
  }

  componentWillReceiveProps(newProps){
    const { realtor, dispatch } = this.props;
    if(newProps.match.params.id !== this.state.id || newProps.match.params.type != this.state.type){

      if(!realtor.listingDetailCache[newProps.match.params.id]){
        dispatch(tryToFetchListingDetail({
          id: newProps.match.params.id,
          type: newProps.match.params.type
        }))
      }

      this.setState({
        id: newProps.match.params.id,
        type: newProps.match.params.type
      })
    }
  }

  render(){
    const { realtor, dispatch } = this.props;
    const state = this.state;
    const id = this.props.match.params.id;

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <ListingDetail listing={realtor.listingDetailCache[this.state.id]}/>
      
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    realtor,
    // ...
  } = state;

  return {
    realtor,
    // ...
  }
}

export default connect(mapStateToProps)(ListingDetailRoute);