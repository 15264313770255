import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import shortid from 'shortid'

import Button from '../Button/Button.js'
import MortgageCalculator from '../MortgageCalculator/MortgageCalculator.js'

import { setContactModalOpen } from '../../actions/actions.export.js'

import './ListingDetail.css'
import listingVideos from '../../listingVideos.js'

const google = window.google;

var feature_sections = [
  {
    name: "Listing Info",
    id: 'info',
    items: [
      "MLS#",
      // "mls",
      "Status",
      "Sub-Status",
      "Sold Date",
      "Market Time",
      "APN",
      "MLS Area",
      "Subdivision",
      // "Directions",

      "Style",
      "Approximate Size (Sq. Ft.)",
      "Square Feet Source",
      "Type",
      "Property Type",
      "Property SubType",
      "Property SubType 2",
      "Total Number of Units",
      "Manufacturer",
      "Is New Construction",
      "Is One Story",
      "Features",
      "Double Wide",
      "Townhouse",
      "Year Built",
      "Age",
      "Model",
      "Actual Zoning",
    ]
  },
  {
    name: "Interior Details",
    id: 'interior',
    items: [
      "Interior Property Features",
      "Total Rooms",
      "# spaces adv",
      // "Total Bathrooms",
      "Number of Bedrooms",
      "Number of Full Bathrooms",
      "Number of Half Bathrooms",
      "Bath Amenities",
      "Dining Room",
      "Family Room",
      "Kitchen",
      "Laundry Room",
      "Living Room",
      "Master Bathroom",
      "Master Bedroom",
      "Bedroom 2",
      "Bedroom 3",
      "Bedroom 4",
      "Bedroom 4",
      "Other Rooms",
      "Attic",
      "Basement",

      "Appliances",
      "Air Conditioning",
      "Electricity",
      "Water",
      "Heat/Fuel",
      "Sewer",
      "Fireplace(s)",
      "Fireplace Description",
      "Has Fireplace",
      "Equipment",
      "Green Energy Features",
      "Energy/Green Building Rating Source",
      "Green Energy search",
      "Disability Equipment Details",
      "Disability Access",
      "Pets Allowed",
    ]
  },
  {
    name: "Exterior Details",
    id: 'exterior,',
    items: [
      "Exposure Faces",
      "Lot Description",
      "Lot Size in Acres",
      "Lot Size",
      "Lot Size in Sq. Ft.",
      "Exterior Property Features",
      "Exterior Features",
      "Waterfront",
      "Driveway",
      "Garage Type",
      "Garage Spaces",
      "Garage",
      "Parking Spaces",
      "Parking Included in Price",
      "Has a Pool",
      "Foundation",
      "Exterior Building Type",
      "Roof",
      "Is a Horse Property",
      "Distressed",
      "Common Area Amenities",
    ]
  },
  {
    name: "Schools",
    id: 'schools',
    items: [
      "School District (of High School)",
      "Elementary School",
      "Jr. High School",
      "High School"
     ]
  },
  {
    name: "Financial Info",
    id: 'financial-info',
    items: [
      "List Price",
      "Sold Price",
      "Is a Preforeclosure",
      "Is a Foreclosure",
      "Is an REO",
      "Security Deposit",
      "Is Short Sale",

      "Tax Amount",
      "Tax Year",
      "Has Assessment/Association Dues",
      "Assessments",
      "Assessments Frequency",
      "Assessments Includes",
      "Monthly Lot Rent Includes",
    ]
  }
  // "MLSPhotoCount",
  // "Remarks",
  // "Has Virtual Tour",
  // "Virtual Tour Link",
  // "Office Code",
  // "Office Name",
  // "Office Phone",
  // "SoldAgentCode",
  // "SoldAgentName",
  // "SoldAgentPhone",
  // "SoldDate",
  // "SoldOfficeCode",
  // "SoldOfficeName",
  // "SoldOfficePhone",
  // "SoldPrice",

  // "photos",
  
  // "Agent Code",
  // "agent Email",
  // "Agent Name",
  // "Agent Phone",
  
  // "bedrooms Above Grade",
  // "bedrooms Below Grade",
  // "Blog Policy",
  
  // "ListDate",
  // "List Price",
  // "ListingStatusDisplay",
  // "Listing Number",
  
  // "Assessments Include",

  // "Full/Partial Address",
  // "Latitude",
  // "Longitude",
  // "Street Number",
  // "Street Name",
  // "Unit Number",
  // "City",
  // "State",
  // "Zip",
  // "County",
  // "co Agent Code",
  // "Possible Best Use",
  // "Possession",
  // "Present Use",
  // "County Search",
  // "Search Policy",
  // "Max. Year Built adv. search",
  // "Not Distressed adv. search",
  // "parking type adv",
  // "High School search",
  // "Elementary School adv search",
  // "active only search",
]

class ListingDetail extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
    this.state = {
      carousel_id: 'carousel-' + shortid.generate(),
      carousel_current: 0,
      image_exists: true
    }

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
  }
  
  onScroll() {
    const { realtor } = this.props;

    var top_padding = 30;

    if(this.state.listing && this.listingDetailDiv && this.houseBookingDiv && this.listingDetailBookingWrapper){
      
      if(window.scrollY > this.listingDetailDiv.offsetTop - top_padding && window.scrollY < this.listingDetailDiv.offsetTop + this.listingDetailDiv.clientHeight - this.houseBookingDiv.clientHeight - top_padding){
        $("#listing-detail-booking-desktop").css({
          position:'fixed', 
          top: 80 + top_padding, 
          width: this.listingDetailBookingWrapper.clientWidth - 30 // for padding
        });
      } else if(window.scrollY >= this.listingDetailDiv.offsetTop + this.listingDetailDiv.clientHeight - this.houseBookingDiv.clientHeight - top_padding){
        $("#listing-detail-booking-desktop").css({
          position:'relative',
          top: this.listingDetailDiv.clientHeight - this.houseBookingDiv.clientHeight,
          width: this.listingDetailBookingWrapper.clientWidth - 30
        });
      } else {
        $("#listing-detail-booking-desktop").css({
          position:'relative',
          top: 0,
          width: this.listingDetailBookingWrapper.clientWidth - 30
        });
      }
    }
  }

  componentWillReceiveProps(newProps){
    if(newProps.listing){
      console.log(newProps.listing);

      // var http = new XMLHttpRequest();
      
      // http.open('HEAD', newProps.listing.photos[0], false);
      // http.send();

      // var image = new Image();

      // image.onload = () => {
      //   console.log('image loaded');
      //   this.setState({
      //     image_exists: true
      //   })
      // }
      // image.onerror = () => {
      //   console.log('image error');
      //   this.setState({
      //     image_exists: false
      //   })
      // }
      // image.src = newProps.listing.photos[0];

      var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
      url += newProps.listing['Street Number']  + ' ' + newProps.listing['Street Name'] + ', ' + newProps.listing.City +', ' + newProps.listing.State;
      url += '&key=' + 'AIzaSyAGIY_qZJYgJVIFmjnmJDwbBGxMPEEpT94';

      fetch(url, {
          method: 'get'
        })
        .then((response) => {
          let json = response.json();
          if (response.status >= 200 && response.status < 300) return json;
          return json.then(Promise.reject.bind(Promise));
        })
        .then(function(json){
          var map = new google.maps.Map(document.getElementById('listing-detail-map'), {zoom: 13, center: json.results[0].geometry.location});

          var marker = new google.maps.Marker({
            map: map,
            position: json.results[0].geometry.location
          });


          // let streetViewElement = document.getElementById("listing-detail-street-view");
          // if(streetViewElement){
          //   const latlng = json.results[0].geometry.location; //{ lat: parseFloat(newProps.listing.lat), lng: parseFloat(newProps.listing.lng) };
          //   const map = new google.maps.Map(document.getElementById("listing-detail-street-view"), {
          //     center: latlng,
          //     zoom: 14,
          //   });
          //   const panorama = new google.maps.StreetViewPanorama(
          //     document.getElementById("listing-detail-street-view"),
          //     {
          //       position: latlng,
          //       pov: {
          //         heading: 34,
          //         pitch: 10,
          //       },
          //     }
          //   );

          //   map.setStreetView(panorama);
          // }
        })



      this.setState({
        listing: newProps.listing
      })

      setTimeout((e)=> {
        $('#' + this.state.carousel_id).on('slide.bs.carousel', (e) => {
          this.setState({
            carousel_current: e.to
          })

          var myElement = document.getElementById('ldgi-' + e.to);
          var leftPos = myElement.offsetLeft;

          if(document.getElementById('listing-detail-gallery-wrapper').scrollLeft > leftPos - 50){
            document.getElementById('listing-detail-gallery-wrapper').scrollLeft = leftPos - 50;
          } else if(document.getElementById('listing-detail-gallery-wrapper').scrollLeft + document.getElementById('listing-detail-gallery-wrapper').getBoundingClientRect().width < leftPos + 15 + myElement.getBoundingClientRect().width){
            document.getElementById('listing-detail-gallery-wrapper').scrollLeft = leftPos + myElement.getBoundingClientRect().width + 15 - document.getElementById('listing-detail-gallery-wrapper').getBoundingClientRect().width;
          }

        })
      }, 10)
    }
  }

  render(){
    const { realtor, dispatch } = this.props;

    var listing = this.state.listing;

    if(!listing || realtor.tryingToGetListingDetail){
      return <p className="lead text-center translucent">
        <br/>
        <i className="fal fa-spinner-third fa-spin fa-fw"/> Loading...
      </p>;
    }

    var available_neighborhoods = ['la-grange', 'la-grange-park', 'western-springs'];
    var neighborhood_key = (listing.City).toLowerCase().replace(/\s/g,'-');

    var neighborhood_match = false;
    var neighborhood_words = neighborhood_key.split('-');
    var neighborhood_title_case = "";
    for(var i in neighborhood_words){
      neighborhood_title_case += neighborhood_words[i][0].toUpperCase() + neighborhood_words[i].substring(1, neighborhood_words[i].length);
      if(i < neighborhood_words.length - 1) neighborhood_title_case += " ";
    }
    if(available_neighborhoods.indexOf(neighborhood_key) > -1){
      neighborhood_match = true;
    }

    var listingVideo = listingVideos[listing.id];

    return <div className="listing-detail">
      <div className="secondary-header secondary-header-small-margin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-home fa-fw secondary-header-icon"></i>&nbsp;
                {listing['Street Number']} {listing['Street Name']} {listing['Unit Number'] ? ('#' + listing['Unit Number']) : "" }<nobr className="text-sm text-400"> {listing.City}, {listing.State} {listing.Zip}</nobr>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/*
            <h1 className="listing-detail-address">
              {listing['Street Number']} {listing['Street Name']} 
              <nobr className="listing-detail-city-state"> {listing.City}, {listing.State} {listing.Zip}</nobr>
            </h1>
            <div className="list-left listing-detail-list">
              <span className="list-item">
                <strong className="listing-detail-price">${parseInt(listing.Price).toLocaleString()}</strong>
              </span>
              <span className="list-item">
                <strong>{listing['Number of Bedrooms']}</strong> beds
              </span>
              <span className="list-item">
                <strong>{listing['Number of Full Bathrooms']}.{listing['Number of Half Bathrooms']}</strong> baths
              </span>
              <span className="list-item">
                <strong>{listing['Approximate Size (Sq. Ft.)']}</strong> sqft
              </span>
            </div>
            */}

            <div className="list-left listing-detail-menu d-none d-md-block">
              {
                listing.Remarks && <Link to={"/listing/" + listing.id + "#about"} className="list-item">About</Link>
              }
              
              <Link to={"/listing/" + listing.id + "#location"} className="list-item">Location</Link>
              {
                feature_sections.map((f, i) => {
                  if(listing.Status == "SOLD"){
                    if(f.name === "Exterior Details") return "";
                    if(f.name === "Schools") return "";
                  }
                  return <Link to={"/listing/" + listing.id + "#" + f.id} className="list-item" key={i}>{f.name}</Link>
                })
              }
              <Link to={"/listing/" + listing.id + "#mortgage-calculator"} className="list-item">Mortgage Calculator</Link>
            </div>
          </div>
          <div className="col-lg-8 col-md-12" ref={ (listingDetailDiv) => this.listingDetailDiv = listingDetailDiv}>

            {
              this.state.image_exists ? 
              <div className="listing-detail-section">
                <div className="listing-detail-hero-wrapper">
                  {
                    listing.Status &&
                      <div className={"listing-detail-status-display " + (listing.Status === "SOLD" ? "listing-detail-status-display-secondary" : "")}>
                      {listing.Status === 'Contingent' ? 'Under Contract' : listing.Status}
                      </div>
                  }
                  <div id={this.state.carousel_id} className="carousel slide" data-interval="false">
                    <ol className="carousel-indicators">
                      {
                        listingVideo && <li data-target={"#" + this.state.carousel_id} data-slide-to={0} className={"active"} key={0}>
                          <i className="fas fa-circle fa-fw"/>
                        </li>
                      }
                      {
                        listing.photos.map((img_url, i)=>{
                          var index = parseInt(i) + (listingVideo ? 1 : 0);
                          return <li data-target={"#" + this.state.carousel_id} data-slide-to={index} className={index == 0 ? "active" : ""} key={index}>
                            <i className="fas fa-circle fa-fw"/>
                          </li>
                        })
                      }
                    </ol>
                    <div className="carousel-inner">
                      {
                        listingVideo && <div className={"carousel-item carousel-item-video active"} key={0} style={{}}>
                            <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/" + listingVideo} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                      }
                      {
                        listing.photos.map((img_url, i)=>{
                          var index = parseInt(i) + (listingVideo ? 1 : 0);
                          return <div className={"carousel-item " + (index == 0 ? "active" : "")} key={index} style={{
                            backgroundImage: 'url(' + img_url + ')',
                            backgroundPosition: 'center center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                          }}>
                            
                          </div>
                        })
                      }
                    </div>
                    <a className="carousel-control-prev" href={"#" + this.state.carousel_id} role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href={"#" + this.state.carousel_id} role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
                <div className="listing-detail-gallery-wrapper" id="listing-detail-gallery-wrapper">
                  {
                    listingVideo && <div id={"ldgi-" + 0} className={"listing-detail-gallery-image " + (0 == this.state.carousel_current ? "active": "")} key={0} data-target={"#" + this.state.carousel_id} data-slide-to={0} >
                        <div className="listing-detail-gallery-video"><i className="fab fa-youtube fa-2x"/></div>
                      </div>
                  }
                  {
                    listing.photos.map((img_url, i) => {
                      var index = parseInt(i) + (listingVideo ? 1 : 0);
                      return <div id={"ldgi-" + index} className={"listing-detail-gallery-image " + (index == this.state.carousel_current ? "active": "")} key={index} data-target={"#" + this.state.carousel_id} data-slide-to={index} >
                        <img src={img_url} height="100%"/>
                      </div>
                    })
                  }
                </div>
              </div>
              :
              <div className="listing-detail-section">
                <div id="listing-detail-street-view" className="listing-detail-map"></div>
              </div>
            }

              
              <div className="listing-detail-section" id="about">
                {
                  listing.Remarks &&
                  <h5>About</h5>
                }
                {
                  listing.Remarks &&
                  <p className="">{listing.Remarks}</p>
                }
                <span id="location" className="listing-detail-directions"><strong>DIRECTIONS:</strong> {listing.Directions}</span>
                <div id="listing-detail-map" className="listing-detail-map"></div>

                <div className="list-right">
                  {
                    neighborhood_match && <Link className="list-item" to={"/neighborhoods/" + neighborhood_key}>
                      <Button display={<span>Learn more about {neighborhood_title_case}</span>} color="secondary"/>
                    </Link>
                  }
                </div>
              </div>

            {
              feature_sections.map((s, i)=> {

                if(listing.Status == "SOLD"){
                  if(s.name === "Exterior Details") return "";
                  if(s.name === "Schools") return "";
                }

                return <div className="listing-detail-section" id={s.id} key={i}>
                  <h5>{s.name}</h5>
                  <div className="listing-detail-feature-block">
                    { 
                      s.items.map((f, j) => {
                        if(!listing[f]) return "";
                        return <div className="listing-detail-feature-row" key={j}>
                          <div className="listing-detail-feature-name">
                            <nobr>{f}</nobr>
                          </div>
                          <div className="listing-detail-feature-value">
                            {listing[f]}
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
              })
            }

            <div className="listing-detail-section">
              <h5 id="mortgage-calculator">Mortgage Calculator</h5>
              <MortgageCalculator 
                home_price={listing.Price}
                down_payment={parseInt(listing.Price * .2)}
                property_taxes={listing.taxes}
                property_taxes_auto={listing.taxes ? false : true}
                small={true}
                />
            </div>

            <div className="listing-detail-section mred-rights">
              <p className="mred-rights-copyright">© 2019 Midwest Real Estate Data LLC. All rights reserved.</p>
              <p>The data relating to real estate for sale on this website comes in part from the Broker Reciprocity program of Midwest Real Estate Data LLC. Some properties which appear for sale on this website may subsequently have sold and may no longer be available. The accuracy of all information, regardless of source, including but not limited to square footages and lot sizes, is deemed reliable but not guaranteed and should be personally verified through personal inspection by and/or with the appropriate professionals. The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing.</p>
            </div>
          </div>
          
          <div className="col-md-4 d-none d-lg-block" id="listingDetailBookingWrapper" ref={ (listingDetailBookingWrapper) => this.listingDetailBookingWrapper = listingDetailBookingWrapper}>
            <div 
              id="listing-detail-booking-desktop"
              className={"white-container listing-detail-booking-desktop " + (this.state.bookingY > 0 ? " floating" : " docked")} 
              ref={ (houseBookingDiv) => this.houseBookingDiv = houseBookingDiv}
              >
              {
                realtor.myListingIDs.indexOf(listing.id) > -1 &&
                <span className="corner-ribbon top-right">LPP Listing</span>
              }
              <h3>
                ${parseInt(listing.Price).toLocaleString()}
              </h3>
              <p>
                <span className="text-lg">{listing['Street Number']} {listing['Street Name']} {listing['Unit Number'] ? ('#' + listing['Unit Number']) : "" }</span><br/>
                {listing.City}, {listing.State} {listing.Zip}
              </p>
              <div className="list-left listing-detail-list">
                <span className="list-item">
                  <strong>{listing['Number of Bedrooms']}</strong> beds
                </span>
                <span className="list-item">
                  <strong>{listing['Number of Full Bathrooms']}.{listing['Number of Half Bathrooms']}</strong> baths
                </span>
                <span className="list-item">
                  <strong>{listing['Approximate Size (Sq. Ft.)']}</strong> sqft
                </span>
              </div>

              <hr/>
              <div className="d-none d-xl-block">
                <div className="flex-split-space-around">
                  <img src="/img/headshot-2-square.png" className="listing-detail-avatar"/><br/>
                  <div>
                    <div><i>
                    {
                      realtor.myListingIDs.indexOf(listing.id) > -1 ? 
                      (listing.Status === "SOLD" ? "Sold!" : "Come see this home!")
                      : "Let's check it out!"
                    }
                    </i></div>
                    <div><strong>Lindsey Pann Paulus</strong></div>
                    <div><strong><a href="tel:+1708-926-5034">708-926-5034</a></strong></div>
                  </div>
                </div>
              </div>
              <div className="d-block d-xl-none">
                <div className="flex-center-column text-center">
                  <img src="/img/headshot-2-square.png" className="listing-detail-avatar"/><br/>
                  <div>
                    <div><i>
                    {
                      realtor.myListingIDs.indexOf(listing.id) > -1 ? 
                      (listing.Status === "SOLD" ? "Sold!" : "Come see this home!")
                       : "Let's check it out!"
                    }
                    </i></div>
                    <div><strong>Lindsey Pann Paulus</strong></div>
                    <div><strong><a href="tel:+1708-926-5034">708-926-5034</a></strong></div>
                  </div>
                </div>
              </div>
              <br/>
              <div className="list-item d-none d-lg-block">
                <Button
                  display={<span>Contact Me</span>}
                  color="primary"
                  onClick={(e)=>{ dispatch(setContactModalOpen({open: true, listing_id: listing.id})) }}
                />
              </div>
            </div>
          </div>
          <div className="listing-detail-booking-mobile d-lg-none">
            <div className="flex-split listing-detail-booking-mobile-wrapper">
              <div>
                <div className="listing-detail-booking-mobile-price">
                  ${parseInt(listing.Price).toLocaleString()}
                </div>
                <div className="listing-detail-booking-mobile-address">
                  <span className="text-lg">{listing['Street Number']} {listing['Street Name']} {listing['Unit Number'] ? ('#' + listing['Unit Number']) : "" }</span><br/>
                  {listing.City}, {listing.State} {listing.Zip}
                </div>
              </div>
              <div>
                <div className="list-item">
                  <Button
                    display={<span>Contact</span>}
                    color="primary"
                    onClick={(e)=>{ dispatch(setContactModalOpen({open: true, listing_id: listing.id})) }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

}


const mapStateToProps = (state) => {
  const { 
    realtor
    //...
  } = state;

  return {
    realtor
    //...
  }
}

export default connect(mapStateToProps)(ListingDetail);