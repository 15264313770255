import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid';
import ScrollAnimation from 'react-animate-on-scroll';

import './ListingRow.css';

import ListingThumbnail from '../ListingThumbnail/ListingThumbnail.js'
import Button from '../Button/Button.js'

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

class ListingRow extends Component {
  static propTypes = {
    numberToShow: PropTypes.number
  };

  static defaultProps = {
    numberToShow: 3
  }

  constructor(props){
    super(props);
  }

  render(){
    const { realtor, dispatch } = this.props;

    var listings_to_show = shuffle(JSON.parse(JSON.stringify(realtor.listings)))

    listings_to_show.sort((a, b)=>{
      if(a.status !== "SOLD" && b.status === "SOLD") return -1;
      if(a.status === "SOLD" && b.status !== "SOLD") return 1;
      return 0;
    })

    listings_to_show = listings_to_show.splice(0,4);

    return <div className="listing-row">
      <div className="container">
        {/*<div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h3>LPP Listings</h3>
          </div>
        </div>*/}
        <div className="row justify-content-center row-padded">
          {
            listings_to_show.map((l, i)=>{
              return <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" key={i}>
                <ListingThumbnail
                  id={l.id}
                  type={l.type}
                  img_url={l.photo}
                  address={l.address}
                  city={l.city}
                  state={l.state}
                  zip={l.zip}
                  beds={l.beds}
                  full_baths={l.full_baths}
                  half_baths={l.half_baths}
                  sqft={l.sqft}
                  price={l.price}
                  status={l.status}
                />
              </div>
            })
          }
        </div>
        <div className="row row-padded">
          <div className="col-md-12">
            <div className="list-center">
              <ScrollAnimation animateIn="pulse">
                <Link to="/listings"><Button display={<span>See all of my listings&nbsp;&nbsp;<i className="fas fa-lg fa-angle-right"/></span>} /></Link>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const {
    realtor 
    //...
  } = state;

  return {
    realtor
    //...
  }
}

export default connect(mapStateToProps)(ListingRow);