import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ListingThumbnail from '../components/ListingThumbnail/ListingThumbnail.js'

import vendors from '../vendor_data.js';

const google = window.google;

var map;
var service; 
var infowindow;
var bounds;

class VendorsRoute extends Component {
  constructor(props){
    super(props)

    this.state = {
      markers_loaded: false
    }
  }

  componentDidMount(){
    const { realtor } = this.props;

    var zoom = 11;

    map = new google.maps.Map(document.getElementById('listings-map'), {zoom: zoom, center: {lat: 41.8054577, lng: -87.8748644}});

    infowindow = new google.maps.InfoWindow();

    if(this.props.realtor.listings.length > 0 && !this.state.markers_loaded){
      for(var i in this.props.realtor.listings){
        var l = this.props.realtor.listings[i]
        this.addMarker(l, i)
      }
      this.setState({
        markers_loaded: true
      })
    }
    
  }

  addMarker(l, index){
    var cleanAddress = l.address.split('#')[0]; // remove unit to prevent # from breaking our URL format

    var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
    url += ' ' + cleanAddress + ', ' + l.city +', ' + l.state;
    url += '&key=' + 'AIzaSyAGIY_qZJYgJVIFmjnmJDwbBGxMPEEpT94';
    
    bounds  = new google.maps.LatLngBounds();

    const finishMarker = location => {
      var marker = new google.maps.Marker(
      // {
      //   map: map,
      //   label: {
      //     text: (parseInt(index) + 1).toString(),
      //     color: 'white',
      //   },
      //   position: location
      // }
        {
          map: map,
          label: {
            // text: (parseInt(index) + 1).toString(),
            // color: 'white'
          },
          position: location, //json.results[0].geometry.location,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 6,
            fillColor: l.status === "SOLD" ?  "#1F9FF4" : "#E01B22",
            fillOpacity: 1,
            strokeColor: '#FFF',
            strokeWeight: 1
          }
        });

      var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(loc);
      // map.fitBounds(bounds);      
      // map.panToBounds(bounds);    

      google.maps.event.addListener(marker, 'click', function() {
        infowindow.setContent('\
          <a href="/listing/' + l.id + '" class="map-thumbnail">\
            <img class="map-thumbnail-img" src="' + l.photo + '" width="150px"/><br/><br/>\
            <span class="map-thumbnail-address">' + l.address + '</span>\
          </a>');
        infowindow.open(map, this);
      });
    }

    if(l.lat && l.lng){
      // console.log(l);
      finishMarker({
        lat: parseFloat(l.lat),
        lng: parseFloat(l.lng)
      })
    } else {
      // console.log("QUERY");
      fetch(url, {
          method: 'get'
        })
        .then((response) => {
          let json = response.json();
          if (response.status >= 200 && response.status < 300) return json;
          return json.then(Promise.reject.bind(Promise));
        })
        .then(function(json){
          finishMarker(json.results[0].geometry.location);
        })
    }
  }

  componentWillReceiveProps(newprops){
    
    if(newprops.realtor.listings.length > 0 && !this.state.markers_loaded){
      for(var i in newprops.realtor.listings){
        var l = newprops.realtor.listings[i]
        this.addMarker(l, i)
      }
      this.setState({
        markers_loaded: true
      })
    }
  }

  render(){
    const { dispatch, realtor } = this.props;
 

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header secondary-header-small-margin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-home-heart fa-fw secondary-header-icon"></i>&nbsp;
                LPP Listings
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row equal row-push-down">
          <div className="col-md-6">
            {/*<img src="/img/listings_map.png" width="100%"/>*/}
            <div id="listings-map" className="map">
            </div>
          </div>
          <div className="col-md-6">
            <div className=" overflow-y-scroll-listings">
              <h5>Looking to sell your home?</h5>
              <p>
                 I would love to help! I will listen and work hard to help you reach your goals and enjoy it every step of the way. I work with a smile and believe in positivity and I can’t wait to get to know you, earn your trust and help you with one of the biggest sales of your lifetime.
              </p>

              {
                realtor.tryingToGetListings && <p className="lead translucent">
                  <i className="fal fa-spinner-third fa-spin fa-fw"/> Loading...
                  <br/>
                </p>
              }
              <div className="row">
                  {
                    realtor.listings.map((l, i) => {
                      return <div className="col-md-6" key={i}>
                        <ListingThumbnail
                          id={l.id}
                          type={l.type}
                          img_url={l.photo}
                          address={l.address}
                          city={l.city}
                          state={l.state}
                          zip={l.zip}
                          beds={l.beds}
                          full_baths={l.full_baths}
                          half_baths={l.half_baths}
                          sqft={l.sqft}
                          price={l.price}
                          status={l.status}
                          img_label={i + 1}
                        />
                      </div>
                    })
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    realtor,
    // ...
  } = state;

  return {
    realtor,
    // ...
  }
}

export default connect(mapStateToProps)(VendorsRoute);