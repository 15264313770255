import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './Review.css';

class Review extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {
   
  }

  constructor(props){
    super(props);
  }

  render(){
    const { user, dispatch } = this.props;

    var stars = []
    for(var i = 0; i < this.props.stars; i++){
      stars.push(<i key={i} className="fas fa-star"/>);
    }

    return <div className="review white-container">
      <div className="review-body">
        <i className="fas fa-quote-left"/>
        {this.props.body}
        <i className="fas fa-quote-right"/>
      </div>
      <div className="review-info">
        {
          this.props.image ? <div className="review-avatar" style={{backgroundImage: 'url(' + this.props.image + ')'}}></div> : ""
        }
        
        <div className="review-name-and-stars">
          <div className="review-stars">
            {stars}
          </div>
          <div className="review-name">
            {this.props.name} 
          </div>
          <div className="review-location">{this.props.location}</div>
          
        </div>
      </div>
    </div>
  }

  compnentWillReceiveProps(newProps){
    
  }   
}


const mapStateToProps = (state) => {
  const { 
    //...
  } = state;

  return {
    //...
  }
}

export default connect(mapStateToProps)(Review);