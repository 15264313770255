import { push } from 'react-router-redux';

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// BlogPosts 
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestBlogPosts = credentials => {
  return {
    type: 'REQUEST_BLOG_POSTS'
  }
}

export const receiveBlogPostsSuccess = data => ({
  type: 'RECEIVE_BLOG_POSTS_SUCCESS',
  data
})

export const receiveBlogPostsFail = data => ({
  type: 'RECEIVE_BLOG_POSTS_FAIL',
  data
})

export const tryToFetchBlogPosts = packet => dispatch => {
  dispatch(requestBlogPosts());

  let queryString = "";
  if(packet) queryString = packet.queryString;

  
  return fetch('/api/blog/posts?' + queryString, {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){

    json.queryString = queryString;
    json.queryParams = packet ? packet.queryParams : {};

    if(json.posts.length == 0 && (window.location.pathname == '/blog' || window.location.pathname.indexOf('/blog/posts/with/tag/') === 0 || window.location.pathname.indexOf('/blog/posts/in/category/') === 0)){
      dispatch(push('/'));
    }

    for(var i in json.posts){
      if(json.posts[i]._links['wp:featuredmedia']){
        dispatch(tryToFetchFeaturedMedia(json.posts[i]));
      }
    }

    return dispatch(receiveBlogPostsSuccess(json));
  })
  .catch(e => {
    dispatch(receiveBlogPostsFail({error:e, packet: packet}));
  })
}

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Specific blog post
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestBlogPost = credentials => {
  return {
    type: 'REQUEST_BLOG_POST'
  }
}

export const receiveBlogPostSuccess = data => ({
  type: 'RECEIVE_BLOG_POST_SUCCESS',
  data
})

export const receiveBlogPostFail = data => ({
  type: 'RECEIVE_BLOG_POST_FAIL',
  data
})

export const tryToFetchBlogPost = packet => dispatch => {
  dispatch(requestBlogPost());
  
  return fetch('/api/blog/posts/id/' + packet.id, {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    if(json._links['wp:featuredmedia']){
      dispatch(tryToFetchFeaturedMedia(json));
    }

    return dispatch(receiveBlogPostSuccess(json));
  })
  .catch(e => {
    dispatch(push('/blog'));
    dispatch(receiveBlogPostFail({error:e}));
  })
}

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Categories 
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestBlogCategories = credentials => {
  return {
    type: 'REQUEST_BLOG_CATEGORIES'
  }
}

export const receiveBlogCategoriesSuccess = data => ({
  type: 'RECEIVE_BLOG_CATEGORIES_SUCCESS',
  data
})

export const receiveBlogCategoriesFail = data => ({
  type: 'RECEIVE_BLOG_CATEGORIES_FAIL',
  data
})

export const tryToFetchBlogCategories = packet => dispatch => {
  dispatch(requestBlogCategories());
  
  return fetch('/api/blog/categories', {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    return dispatch(receiveBlogCategoriesSuccess(json));
  })
  .catch(e => {
    dispatch(receiveBlogCategoriesFail({errors:e}));
  })
}

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Tags 
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestBlogTags = credentials => {
  return {
    type: 'REQUEST_BLOG_TAGS'
  }
}

export const receiveBlogTagsSuccess = data => ({
  type: 'RECEIVE_BLOG_TAGS_SUCCESS',
  data
})

export const receiveBlogTagsFail = data => ({
  type: 'RECEIVE_BLOG_TAGS_FAIL',
  data
})

export const tryToFetchBlogTags = packet => dispatch => {
  dispatch(requestBlogTags());
  
  return fetch('/api/blog/tags', {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    return dispatch(receiveBlogTagsSuccess(json));
  })
  .catch(e => {
    dispatch(receiveBlogTagsFail({errors:e}));
  })
}

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Featured Media 
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestFeaturedMedia = credentials => {
  return {
    type: 'REQUEST_FEATURED_MEDIA'
  }
}

export const receiveFeaturedMediaSuccess = data => ({
  type: 'RECEIVE_FEATURED_MEDIA_SUCCESS',
  data
})

export const receiveFeaturedMediaFail = data => ({
  type: 'RECEIVE_FEATURED_MEDIA_FAIL',
  data
})

export const tryToFetchFeaturedMedia = packet => dispatch => {
  dispatch(requestFeaturedMedia());
 
  return fetch(packet._links['wp:featuredmedia'][0].href, {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    let post = packet;
    post.featuredMedia = json;
    return dispatch(receiveFeaturedMediaSuccess(post));
  })
  .catch(e => {
    dispatch(receiveFeaturedMediaFail({errors:e}));
  })
}

