import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import shortid from 'shortid'
import { Link } from 'react-router-dom'
import $ from 'jquery'

// import Button from '../components/Button/Button.js'
import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ListingRow from '../components/ListingRow/ListingRow.js'
import MortgageCalculator from '../components/MortgageCalculator/MortgageCalculator.js'

class MortgageCalculatorRoute extends Component {
  constructor(props){
    super(props)
  }


  render(){
    const state = this.state;

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-file-invoice-dollar fa-fw secondary-header-icon"></i>&nbsp;
                Mortgage Calculator
              </h1>
            </div>
          </div>
        </div>
      </div>
      <MortgageCalculator/>
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(MortgageCalculatorRoute);