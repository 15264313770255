import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DOMPurify from 'dompurify'

import './BlogSideWidgets.css';

class BlogSideWidgets extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
  }

  render(){
    const { dispatch, blog } = this.props; 
    const state = this.state;

    if(blog.categories.length == 0 && blog.tags.length == 0) return <span/>;

    return (
      <div className="blog-side-widgets white-container">
        {
          blog.categories.length > 0 &&
          <div>
            <p className="lead">All Categories</p>
            <div className="cat-tag-wrapper">
              {
                blog.categories.map((c,i)=>{
                  return <Link to={"/blog/posts/in/category/" + c.slug} className="blog-post-category blog-post-side-widget-block-link" key={i}>{c.name} <small>({c.count})</small></Link>
                })
              }
            </div>
            <br/>
          </div>
        }
        {
          blog.tags.length > 0 && 
          <div>
            <p className="lead">All Tags</p>
            <div className="cat-tag-wrapper">
              {
                blog.tags.map((t,i)=>{
                  return <Link to={"/blog/posts/with/tag/" + t.slug} className="blog-post-tag blog-post-side-widget-block-link" key={i}>{t.name} <small>({t.count})</small></Link>
                })
              }
            </div>
          </div>
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { blog } = state;

  return {
    blog
  }
}

export default connect(mapStateToProps)(BlogSideWidgets);
