import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'

// import Button from '../components/Button/Button.js'
import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ListingRow from '../components/ListingRow/ListingRow.js'

import { setContactModalOpen } from '../actions/actions.export.js'

class Buyers extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    var sections = [
      {
        title: "Home Inspections",
        videos: [
          { code: "fU0qNByg24M", title: "What are some red flags in a home inspection?" },
          { code: "eVuEpWjeTKk", title: "What is the home inspection process?" },
        ]
      },
      {
        title: "Financing",
        videos: [
          { code: "5lofgUncuP4", title: "What is underwriting and who is the underwriter?" },
          { code: "QbcHMxnFHRw", title: "What is the mortgage process?" },
          { code: "y9lYaiHsNQ8", title: "Prequalification vs. Preapproval" },
          { code: "MvqUgqXBIZU", title: "One of the biggest reasons deals fall apart" },
          { code: "RceAhQhxDeQ", title: "How long does it take to get pre approved?" },
          { code: "BwZif7quvSU", title: "How long does it take to close?" },
          { code: "Ee0hHGjZink", title: "All About Credit" }
        ]
      },
      {
        title: "Home Owners Insurance",
        videos: [
          { code: "rDgDG0LCmM8", title: "Homeowners Insurance Overview" },
          { code: "aMqPEyHZgjM", title: "What are the Costs of Homeowners Insurance" },
          { code: "x81p-bpu4t8", title: "Top 3 Reasons for Using Homewoners Insurance" },
          { code: "bpQFYIrrQ4w", title: "When should I call to get an insurance update?" }
        ]
      }
    ]

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-money-check-edit-alt fa-fw secondary-header-icon"></i>&nbsp;
                Buyers
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="lead">
              Thinking about buying a house? 
              &nbsp;Click <a href="#" onClick={(e)=>{ dispatch(setContactModalOpen({message: "Hi Lindsey, please contact me so we can talk about my goals to buy."}))}}>here</a> to contact Lindsey and start the process.
            </p>
          </div>
        </div>
        <hr/>
        <br/>
        {
          sections.map((s, i)=>{
            return <div className="row row-push-down" key={i}>
              <div className="col-md-12">
                <h3 className="text-lpp-primary">{s.title}</h3>
                <br/>
              </div>
              {
                s.videos.map((v, i)=>{
                  return <div className="col-md-6" key={i}>
                    <div className="white-container">
                      <p className="lead">{v.title}</p>
                      <div className="embed-container">
                        <iframe class="embed-video" width="100%" height="" src={"https://www.youtube.com/embed/" + v.code} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                })
              }   
            </div>
          })
        }
      </div>
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(Buyers);