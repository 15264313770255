import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'

import ScrollToTop from './ScrollToTop.js'

import Home from './routes/Home.js'
import ListingDetailRoute from './routes/ListingDetailRoute.js'
import NeighborhoodRoute from './routes/NeighborhoodRoute.js'
import ClientResourcesRoute from './routes/ClientResourcesRoute.js'
import SearchRoute from './routes/SearchRoute.js'
import ListingsRoute from './routes/ListingsRoute.js'
import VendorsRoute from './routes/VendorsRoute.js'
import Decluttering911 from './routes/Decluttering911.js'
import BuyersRoute from './routes/BuyersRoute.js'
import SellersRoute from './routes/SellersRoute.js'
import MortgageCalculatorRoute from './routes/MortgageCalculatorRoute.js'
import NetSheetRoute from './routes/NetSheetRoute.js'
import BlogRoute from './routes/BlogRoute.js'
import BlogPostRoute from './routes/BlogPostRoute.js'
import SideMenu from './components/SideMenu/SideMenu.js'


import Tooltip from './components/Tooltip/Tooltip.js'
import ContactModal from './components/ContactModal/ContactModal.js'


import { 
  toggleSideMenu, 
  tryToFetchListings,
  tryToFetchBlogPosts,
  tryToFetchBlogCategories,
  tryToFetchBlogTags
} from './actions/actions.export.js'


class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
    this.handleSideMenuClose = this.handleSideMenuClose.bind(this);
  }

  handleSideMenuClose(){
    const {globalReducer, dispatch} = this.props;
    if(globalReducer.sidemenu.open) dispatch(toggleSideMenu(false));
  }

  componentDidMount(){
    const { dispatch } = this.props;
    dispatch(tryToFetchListings());
    // dispatch(tryToFetchBlogCategories());
    // dispatch(tryToFetchBlogTags());
    // dispatch(tryToFetchBlogPosts());
  }

  render(){
    const { history } = this.props;

    return <ConnectedRouter history={history}>
      <ScrollToTop>
        <div>
          <Tooltip/>
          <SideMenu/>
          <ContactModal/>
          <div className="body-wrapper" onClick={this.handleSideMenuClose}>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/temp/listing-detail-page" component={ListingDetailRoute} />
              <Route exact path="/neighborhoods/:neighborhood" component={NeighborhoodRoute} />
              <Route exact path="/neighborhoods" component={NeighborhoodRoute} />
              <Route exact path="/listings" component={ListingsRoute} />
              <Route exact path="/listing/:id" component={ListingDetailRoute} />
              <Route exact path="/search" component={SearchRoute} />
              <Route exact path="/client-resources" component={ClientResourcesRoute} />
              <Route exact path="/client-resources/recommended-vendors" component={VendorsRoute} />
              <Route exact path="/client-resources/mortgage-calculator" component={MortgageCalculatorRoute} />
              <Route exact path="/client-resources/net-sheet-calculator" component={NetSheetRoute} />
              <Route exact path="/client-resources/decluttering-911" component={Decluttering911} />
              <Route exact path="/buyers" component={BuyersRoute} />
              <Route exact path="/sellers" component={SellersRoute} />
              <Route exact path="/blog" component={BlogRoute} />
              <Route exact path="/blog/post/:id" component={BlogPostRoute} />
              <Route exact path="/blog/posts/in/category/:categorySlug" component={BlogRoute} />
              <Route exact path="/blog/posts/with/tag/:tagSlug" component={BlogRoute} />
              <Redirect to='/' />
            </Switch>
          </div>
        </div>
      </ScrollToTop>
    </ConnectedRouter>
  }
}

const mapStateToProps = (state) => {
  const { 
    globalReducer
    // nav
    //...
  } = state;

  return {
    globalReducer
    // nav
    //...
  }
}

export default connect(mapStateToProps)(App);