import React, { Component } from 'react'
import { push, goBack } from 'react-router-redux';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import shortid from 'shortid'

import './ContactModal.css';

import Button from '../../components/Button/Button.js'
import ContactForm from '../../components/ContactForm/ContactForm.js'
import ListingThumbnail from '../../components/ListingThumbnail/ListingThumbnail.js'

import { setContactModalOpen } from '../../actions/actions.export.js';


class ContactModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.state = {
      // listing_id: props.globalReducer.contactModal.listing_id
    }

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal(){
    const { dispatch } = this.props;

    this.setState({
      
    }, (e) => {
      dispatch(setContactModalOpen(false))      
    })
  }


  render(){
    const { dispatch, globalReducer, realtor } = this.props; 
    
    if(!globalReducer.contactModal.open) return "";

    var l = false;

    for(var i in realtor.listingDetailCache){
      if(realtor.listingDetailCache[i].id == globalReducer.contactModal.listing_id){
        l = realtor.listingDetailCache[i];
        break;
      }
    }

    return <div className="modal theme-light">
      <div className="modal-bg" onClick={this.handleCloseModal}>
      </div>
      <div className="modal-inner">
        <div className="flex-split">
          <span>
            <strong></strong>
          </span>
          <span>
            <Button size="medium" display={<i className="fal fa-times fa-lg fa-fw"/>} onClick={this.handleCloseModal}/> 
          </span>
        </div>
        {
          l ?
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <h5>Interested?</h5>
                <ListingThumbnail
                    id={l.id}
                    type={l.Type}
                    img_url={l.photos[0]}
                    address={l['Street Number'] + ' ' + l['Street Name'] +  (l['Unit Number'] ? (' #' + l['Unit Number']) : "")}
                    city={l.City}
                    state={l.State}
                    zip={l.Zip}
                    beds={l['Number of Bedrooms']}
                    full_baths={l['Number of Full Bathrooms']}
                    half_baths={l['Number of Half Bathrooms']}
                    sqft={l['Approximate Size (Sq. Ft.)']}
                    price={l.Price}
                    status={l.Status}
                    isMyListing={realtor.myListingIDs.indexOf(l.id) > -1}
                  />
              </div>
            <div className="col-md-6">
              <ContactForm/>
            </div>
          </div>
        </div>
        :
        <ContactForm/>
      }
      </div>
    </div>
    
  }
}
const mapStateToProps = (state) => {
  const { globalReducer, realtor } = state;

  return {
    globalReducer, realtor
  }
}

export default connect(mapStateToProps)(ContactModal);
