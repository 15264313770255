import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './SideMenu.css';

import { toggleSideMenu, setContactModalOpen } from '../../actions/actions.export.js'
import Button from '../Button/Button.js'

class SideMenu extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
    this.state = {
      open: false
    }

    this.handleSideMenuToggle = this.handleSideMenuToggle.bind(this);
  }

  handleSideMenuToggle(){
    const { dispatch } = this.props;
    dispatch(toggleSideMenu())
  }

  render(){
    const { globalReducer, dispatch, blog } = this.props;
    
    let blogPostCount = Object.keys(blog.postCache).length;
    for(let i in blog.postCache){
      if(blog.postCache[i].categories.indexOf(13) > -1){
        blogPostCount--;
      }
    }

    return <div id="sidemenu" className={globalReducer.sidemenu.open ? "sidemenu-open" : "sidemenu-closed"}>
      <div className="clickable sidemenu-exit-btn" onClick={this.handleSideMenuToggle}>
        <i className="fal fa-times fa-2x"/>
      </div>
      <div className="sidemenu-header">
        <strong>Lindsey Pann Paulus</strong><br/>
        <strong>708-926-5034</strong><br/>
        <span className="lb-email">lindsey@lindseypaulus.com</span>
      </div>
      <div className="sidemenu-list">
        
        <Link onClick={this.handleSideMenuToggle} to="/listings" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">LPP Listings</Link>
        <Link onClick={this.handleSideMenuToggle} to="/search" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">MLS Search</Link>
        <Link onClick={this.handleSideMenuToggle} to="/neighborhoods" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">Neighborhoods</Link>
        <Link onClick={this.handleSideMenuToggle} to="/buyers" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">Buyers</Link>
        <Link onClick={this.handleSideMenuToggle} to="/sellers" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">Sellers</Link>
        <Link onClick={this.handleSideMenuToggle} to="/#about" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">About</Link>

        {
          blogPostCount > 0 &&
          <Link onClick={this.handleSideMenuToggle} to="/blog" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">Blog</Link>
        }
        <Link onClick={this.handleSideMenuToggle} to="/client-resources/recommended-vendors" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">Recommended Vendors</Link>
        <Link onClick={this.handleSideMenuToggle} to="/client-resources/decluttering-911" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">Decluttering 911</Link>
        <Link onClick={this.handleSideMenuToggle} to="/client-resources/mortgage-calculator" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">Mortgage Calculator</Link>
        <Link onClick={this.handleSideMenuToggle} to="/client-resources/net-sheet-calculator" className="sidemenu-link sidemenu-item underlined underlined-R2L underlined-secondary">Net Sheet Calculator</Link>
        
        
      </div>

      <div  className="sidemenu-link sidemenu-item">
        <Button
          display={<span>Contact</span>}
          color="primary"
          onClick={(e)=>{ 
            dispatch(setContactModalOpen({open: true}));
            this.handleSideMenuToggle()
          }}
        />
      </div>
      
      <span className="logo-wrapper">
        <img src="/img/lpp-black-nobg-small.png" height="100px"/>
      </span>
    </div>
  }

  compnentWillReceiveProps(newProps){
    this.setState({
      open: newProps.open
    });
  }
}


const mapStateToProps = (state) => {
  const { 
    globalReducer,
    blog
    //...
  } = state;

  return {
    globalReducer,
    blog
    //...
  }
}

export default connect(mapStateToProps)(SideMenu);