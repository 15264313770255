import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'

// import Button from '../components/Button/Button.js'
import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ListingRow from '../components/ListingRow/ListingRow.js'

import { setContactModalOpen } from '../actions/actions.export.js'

class Sellers extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    var sections = [
      {
        title: "Home Care",
        videos: [
          { code: "u6x2CEPBSQ4", title: "Advice for Home Maintenance" },
          { code: "9SvbLopWJeA", title: "Inspection Advice for Sellers" },
          { code: "eVuEpWjeTKk", title: "What is the home inspection process?" },
        ]
      },
      {
        title: "Photography",
        videos: [
          { code: "2S6OYRLtR64", title: "Why Photos Are Important" },
          { code: "8jbCqw5anRQ", title: "What happens after photos are taken?" },
          { code: "Uz8J1bMAzB0", title: "Where is real estate photography going next?" },
          { code: "PTLknJ9Uzjc", title: "What about night photos and drone footage?" },
          { code: "-eflAb6TeIE", title: "Main Objectives in Shooting a Home" },
          { code: "jQVQ4FpbJ0E", title: "How long does it take to shoot a home?" },
          { code: "uk0SgIcCw2w", title: "How do you differentiate yourself from other photographers?" },
          { code: "5NsI8X77Iqs", title: "How does VHT differentiate from other photographers?" },
          { code: "1B4uRce8T8s", title: "Listing Videos"}
        ]
      },
      {
        title: "Sample Listing Video",
        videos: [
          { code: "QPE8IHDlzIc", title: "624 Glenwood Lane | La Grange, IL 60525"}
        ]
      }

      // { code: "MvqUgqXBIZU", title: "One of the biggest reasons deals fall apart" },
      // { code: "RceAhQhxDeQ", title: "How long does it take to get pre approved?" },
      // { code: "BwZif7quvSU", title: "How long does it take to close?" },
      // { code: "Ee0hHGjZink", title: "All About Credit" }
    ]

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-money-check-edit-alt fa-fw secondary-header-icon"></i>&nbsp;
                Sellers
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="lead">
              Thinking about selling your home? 
              &nbsp;Click <a href="#" onClick={(e)=>{ dispatch(setContactModalOpen({message: "Hi Lindsey, please contact me to set up my free home valuation and strategy session."}))}}>here</a> for a free home valuation and strategy session.
            </p>
          </div>
        </div>
        <hr/>
        <br/>
        {
          sections.map((s, i)=>{
            return <div className="row row-push-down" key={i}>
              <div className="col-md-12">
                <h3 className="text-lpp-primary">{s.title}</h3>
                <br/>
              </div>
              {
                s.videos.map((v, i)=>{
                  return <div className="col-md-6" key={i}>
                    <div className="white-container">
                      <p className="lead">{v.title}</p>
                      <div className="embed-container">
                        <iframe class="embed-video" width="100%" height="" src={"https://www.youtube.com/embed/" + v.code} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                })
              }   
            </div>
          })
        }
      </div>
      
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(Sellers);