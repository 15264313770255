////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// LISTINGS 
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestListings = credentials => {
  return {
    type: 'REQUEST_LISTINGS'
  }
}

export const receiveListingsSuccess = data => ({
  type: 'RECEIVE_LISTINGS_SUCCESS',
  data
})

export const receiveListingsFail = data => ({
  type: 'RECEIVE_LISTINGS_FAIL',
  data
})

export const tryToFetchListings = packet => dispatch => {
  dispatch(requestListings());
  
  return fetch('/api/listings', {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    return dispatch(receiveListingsSuccess(json));
  })
  .catch(e => {
    dispatch(receiveListingsFail({errors:e}));
  })
}

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// LISTING DETAIL
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestListingDetail = credentials => {
  return {
    type: 'REQUEST_LISTING_DETAIL'
  }
}

export const receiveListingDetailSuccess = data => ({
  type: 'RECEIVE_LISTING_DETAIL_SUCCESS',
  data
})

export const receiveListingDetailFail = data => ({
  type: 'RECEIVE_LISTING_DETAIL_FAIL',
  data
})

export const tryToFetchListingDetail = packet => dispatch => {
  dispatch(requestListingDetail());
  
  return fetch('/api/listings/' + packet.id + '/NA/scrape', {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    return dispatch(receiveListingDetailSuccess(json));
  })
  .catch(e => {
    dispatch(receiveListingDetailFail({errors:e}));
    console.log("FAIL");
    return window.location.replace('/');
  })
}

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// SEARCH LISTINGS 
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestSearchListings = credentials => {
  return {
    type: 'REQUEST_SEARCH_LISTINGS'
  }
}

export const receiveSearchListingsSuccess = data => ({
  type: 'RECEIVE_SEARCH_LISTINGS_SUCCESS',
  data
})

export const receiveSearchListingsFail = data => ({
  type: 'RECEIVE_SEARCH_LISTINGS_FAIL',
  data
})

export const tryToFetchSearchListings = packet => dispatch => {
  dispatch(requestSearchListings());

  var url = '/api/listings/search/mls/by/city?';

  var keys = Object.keys(packet);
  for(var i in keys){
    url += keys[i] + '=' + packet[keys[i]];
    if(parseInt(i) < keys.length - 1) url += '&';
  }
 

  return fetch(url, {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    console.log(json);
    return dispatch(receiveSearchListingsSuccess(json));
  })
  .catch(e => {
    console.log(e);
    dispatch(receiveSearchListingsFail({errors:e}));
  })
}