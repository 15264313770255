import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid';

import CustomField from '../CustomField/CustomField.js'
import './MortgageCalculator.css';

function cleanNumber(n){
  return Number((n + "").replace(/[^0-9.-]+/g,""));
}

const property_tax_rate = .01875;
const insurance_rate = .0042;
const pmi_rate = 0.0004167;

class MortgageCalculator extends Component {
  static propTypes = {
    home_price: PropTypes.number,
    down_payment: PropTypes.number,
    interest_rate: PropTypes.string,
    property_taxes_auto: PropTypes.bool,
    loan_term: PropTypes.number,
    insurance_auto: PropTypes.bool
  };

  static defaultProps = {
    home_price: 300000,
    down_payment: 60000,
    interest_rate: '5.0%',
    property_taxes_auto: true,
    loan_term: 30,
    insurance_auto: true
  }

  constructor(props){
    super(props);
    this.updateCalculator = this.updateCalculator.bind(this);
    this.state = {
      home_price: props.home_price,
      down_payment: props.down_payment,
      interest_rate: props.interest_rate,
      property_taxes_auto: props.property_taxes_auto,
      property_taxes: props.property_taxes,
      loan_term: props.loan_term,
      insurance_auto: props.insurance_auto
    };
  }

  componentDidMount(){
    this.updateCalculator(this.state);
  }

  updateCalculator(data){
    var new_state = Object.assign({}, this.state, data)

    if(data.home_price){
      new_state.home_price = cleanNumber(new_state.home_price);
      new_state.home_price_formatted = '$' + (new_state.home_price).toLocaleString();
    }
    if(data.down_payment){
      new_state.down_payment = cleanNumber(new_state.down_payment);
      new_state.down_payment_formatted = '$' + (new_state.down_payment).toLocaleString();
    }
    
    if(data.property_taxes){
      new_state.property_taxes = cleanNumber(new_state.property_taxes);
      new_state.property_taxes_formatted = '$' + (new_state.property_taxes).toLocaleString();
    }

    if(data.insurance){
      new_state.insurance = cleanNumber(new_state.insurance);
      new_state.insurance_formatted = '$' + (new_state.insurance).toLocaleString();
    }

    if(data.interest_rate){
      new_state.interest_rate_number = Number(new_state.interest_rate.replace(/[^0-9.-]+/g,""));
    }


    if(this.state.property_taxes_auto){
      new_state.property_taxes = new_state.home_price * property_tax_rate;
      new_state.property_taxes_formatted = '$' + Math.round(new_state.property_taxes).toLocaleString();
    }

    if(this.state.insurance_auto){
      new_state.insurance = new_state.home_price * insurance_rate;
      new_state.insurance_formatted = '$' + Math.round(new_state.insurance).toLocaleString();
    }

    new_state.monthly_principal = (new_state.home_price - new_state.down_payment) * (new_state.interest_rate_number / 12 / 100 * Math.pow(1 + new_state.interest_rate_number / 12 / 100, new_state.loan_term * 12)) / (Math.pow(1 + new_state.interest_rate_number / 12 / 100, new_state.loan_term * 12) - 1)
    new_state.monthly_insurance = new_state.insurance / 12;
    new_state.monthly_property_taxes = new_state.property_taxes / 12;

    new_state.monthly_pmi = 0;
    if(new_state.down_payment / new_state.home_price < .2){
      new_state.monthly_pmi = (new_state.home_price - new_state.down_payment) * pmi_rate;
    }

    new_state.monthly_total = new_state.monthly_principal + new_state.monthly_insurance + new_state.monthly_property_taxes + new_state.monthly_pmi;

    this.setState(new_state);
  }

  render(){    
    const state = this.state;

    return <div className="mortgage-calculator">
      <div className="container">
        <div className="row">
          <div className={this.props.small ? "col-md-6" : "col-lg-3 col-md-6"} style={{borderRight: "1px solid #eee"}}>
            
            <CustomField
              type="text"
              name="home_price"
              placeholder="Purchase Price"
              description={<span>The total amount you expect to pay for a home before closing costs.</span>}
              autoUpdate={true}
              value={this.state.home_price_formatted}
              onChangeEvent={(e) => {this.updateCalculator({home_price: e}); }}
            />
            <br/>
            <CustomField
              type="text"
              name="down_payment"
              placeholder={"Down Payment (" + (state.down_payment / state.home_price * 100).toFixed(0) + "%)"}
              description={<span>The cash payment you pay upfront when buying a house.</span>}
              autoUpdate={true}
              value={this.state.down_payment_formatted}
              onChangeEvent={(e) => {this.updateCalculator({down_payment: e}); }}
            />
            <br/>
            <CustomField
              type="text"
              name="property_taxes"
              placeholder="Yearly Property Taxes"
              description={
                <span 
                  className="clickable-field-description" 
                  onClick={(e) => { 
                    this.setState({ property_taxes_auto: !state.property_taxes_auto }); 
                    setTimeout((e)=> { this.updateCalculator({}); });
                    
                  }}
                  key={shortid.generate()}
                >
                  {state.property_taxes_auto ? (<i className="far fa-lg fa-toggle-on"/>) : (<i className="far fa-lg fa-toggle-off"/>)}
                  &nbsp;Toggle estimated taxes.
                </span>
              }
              autoUpdate={true}
              value={this.state.property_taxes_formatted}
              disabled={state.property_taxes_auto}
              onChangeEvent={(e) => {this.updateCalculator({property_taxes: e}); }}
            />
            <br/>
            <br/>
          </div>
          <div className={this.props.small ? "col-md-6" : "col-lg-3 col-md-6"} >
            <CustomField
              type="text"
              name="interest_rate"
              placeholder="Interest Rate"
              description={<span>The cost of borrowing money as an annual percentage of the loan.</span>}
              autoUpdate={true}
              value={this.state.interest_rate}
              onChangeEvent={(e) => {this.updateCalculator({interest_rate: e}); }}
              onBlurEvent={(e) => {this.setState({
                  interest_rate: Number((state.interest_rate_number + "").replace(/[^0-9.-]+/g,"")) + "%"
                }) } 
              }
            />
            <br/>
            <CustomField
              type="number"
              name="loan_term"
              placeholder="Loan Term"
              description={<span>The amount of time you have to pay back the loan. Usually 15 or 30 years.</span>}
              autoUpdate={true}
              value={this.state.loan_term}
              onChangeEvent={(e) => {this.updateCalculator({loan_term: e}); }}
            />
            <br/>

            <CustomField
              type="text"
              name="insurance"
              placeholder="Yearly Homeowners Insurance"
              description={
                <span 
                  className="clickable-field-description" 
                  onClick={(e) => { 
                    this.setState({ insurance_auto: !state.insurance_auto }); 
                    setTimeout((e)=> { this.updateCalculator({}); });
                    
                  }}
                  key={shortid.generate()}
                >
                  {state.insurance_auto ? (<i className="far fa-lg fa-toggle-on"/>) : (<i className="far fa-lg fa-toggle-off"/>)}
                  &nbsp;Toggle estimated insurance costs.
                </span>
              }
              autoUpdate={true}
              value={this.state.insurance_formatted}
              disabled={state.insurance_auto}
              onChangeEvent={(e) => {this.updateCalculator({insurance: e}); }}
            />
            <br/>
            <br/>
          </div>

          <div className={this.props.small ? "col-md-12" : "col-lg-6 col-md-12"}>
            <div className="white-container">
              <div className="small-description">Your monthly payment:</div>
              <div className="monthly-payment">{'$' + Math.round(state.monthly_total).toLocaleString()}</div>
              <div className="small-description"></div>
              <div className="mortgage-calculator-table">
                <div className="mortgage-calculator-table-row">
                  <div className="mortgage-calculator-number">{'$' + Math.round(state.monthly_principal).toLocaleString()}</div>
                  <div className="mortgage-calculator-label">Principal & Interest</div>
                  <div className="mortgage-calculator-bar" style={{width: (100*state.monthly_principal/state.monthly_total) + "%"}}></div>
                </div>
                <div className="mortgage-calculator-table-row">
                  <div className="mortgage-calculator-number">{'$' + Math.round(state.monthly_property_taxes).toLocaleString()}</div>
                  <div className="mortgage-calculator-label">Property Taxes</div>
                  <div className="mortgage-calculator-bar" style={{width: (100*state.monthly_property_taxes/state.monthly_total) + "%"}}></div>
                </div>
                <div className="mortgage-calculator-table-row">
                  <div className="mortgage-calculator-number">{'$' + Math.round(state.monthly_insurance).toLocaleString()}</div>
                  <div className="mortgage-calculator-label">Homeowners Insurance</div>
                  <div className="mortgage-calculator-bar" style={{width: (100*state.monthly_insurance/state.monthly_total) + "%"}}></div>
                </div>
                {
                  state.monthly_pmi ? (
                    <div className="mortgage-calculator-table-row">
                      <div className="mortgage-calculator-number">{'$' + Math.round(state.monthly_pmi).toLocaleString()}</div>
                      <div className="mortgage-calculator-label">Required PMI</div>
                      <div className="mortgage-calculator-bar" style={{width: (100*state.monthly_pmi/state.monthly_total) + "%"}}></div>
                    </div>
                  ) : ("")
                }
                <span className="small-description text-left">
                  <i>Calculator for estimation purposes only.</i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }


}


const mapStateToProps = (state) => {
  const { 
    //...
  } = state;

  return {
    //...
  }
}

export default connect(mapStateToProps)(MortgageCalculator);