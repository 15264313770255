  // {
  //   tags: [],
  //   company: "",
  //   name: "",
  //   number: "",
  //   email: "",
  //   testimonials: [
  //     {
  //       body: "",
  //       author: "",
  //       city: ""
  //     }
  //   ]
  // }


const vendors = [
  {
    tags: ["roofing", "roofers"],
    company: "La Grange Roofing",
    name: "Rick Knop",
    number: "708-246-4644",
    email: "rickknop@gmail.com",
    testimonials: [
      {
        body: "Rick did a full tear off/replace on my roof and gutter repairs 2 years ago. He provided different options for me to choose from and helped me find a solution within my budget. His crew was efficient and did a thorough clean up to ensure no nails were left behind.",
        author: "Kristen",
        city: "La Grange"
      },
      {
        body: "Rick Knop of La Grange Roofing is amazing! He fixed a few spots in our roof where squirrels were getting in. He replaced a few missing shingles for free! Super easy to work with, SO reasonable and very responsive! Wld recommend him to anyone!",
        author: "Sara",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["handyman", "general contractors", "flooring", "floors", "contractor", "general", "contractors"],
    company: "Espinos' Flooring",
    name: "Estephan Espinos",
    number: "773-593-4299",
    testimonials: [
      {
        body: "Reasonable price, clean, solid work, and flexible with scheduling",
        author: "Paul",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["general contractors", "contractor", "general", "contractors"],
    company: "Cutting Edge Construction",
    name: "Joe Tucker",
    number: "708-655-7557",
    testimonials: [
      {
        body: "Very knowledgeable, fair price, committed to deadlines for completion, exceptional work and attentive to detail”.",
        author: "Paul",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["plumbing", "plumber"],
    company: "New Image Plumbing",
    name: "Andrew Chuchra",
    number: "773-480-0215",
    testimonials: [
      {
        body: "Experienced, knowledgeable, attentive to details.",
        author: "Paul",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["moving", "storage", "movers"],
    company: "Boerman Moving and Storage",
    name: "Mike Boerman",
    number: "630-972-1000",
    email: "mike@boerman.com",
    testimonials: [
      {
        body: "Mike is honest and the company did a wonderful job moving us out of state.",
        author: "Megan",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["pest control"],
    company: "Awesome Pest Services",
    number: "708-484-6126",
    testimonials: [
      {
        body: "The first one that comes to mind is a pest control service we used a few months ago to deal with a bee issue we were having. They were AMAZING! Came and took care of the nest within ONE HOUR of me calling to inquire, the price was incredibly reasonable, and they guarantee your entire house for 90 days. Wish we had called them earlier!",
        author: "Cheryl",
        city: "Brookfield"
      }
    ]
  },
  {
    tags: ["electrical"],
    company: "Brenner Electric",
    name: "Steve Brenner",
    number: "708-579-0280",
    testimonials: [
      {
        body: "Steve is a very nice and knowledgeable guy.  I’d say he is a mid-priced electrician. He did some wiring and fixture installation on my garage.",
        author: "Kristen",
        city: "La Grange"
      },
      {
        body: "Very knowledgeable and smart. Did good work and was professional. And my daughter-in-law thought he looked like John Denver.",
        author: "Joan",
        city: "Hinsdale"
      }
    ]
  },
  {
    tags: ["plumbing", "plumber"],
    company: "Scott Zelinka Plumbing",
    name: "Scott Zelinka",
    number: "708-261-4087",
    website: "http://scottzelinkaplumbing.com",
    website_display: "scottzelinkaplumbing.com",
    testimonials: [
      {
        body: "I have used Scott and his team for sewer rodding, fixture and pipe installation, sump pump work, hot water tank replacement and clogged drains. He is very responsive, prompt for appointments and reasonably priced. Scott is my go to guy for anything plumbing related.",
        author: "Kristen",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["kitchen", "cabinet"],
    company: "Kitchen Tune Up",
    number: "708-903-1495",
    website: "http://www.kitchentuneup.com/lagrange-il",
    website_display: "kitchentuneup.com",
    testimonials: [
      {
        body: "I wanted to update my 1970s kitchen without a full gut renovation, so I used Kitchen Tune Up to reface the existing cabinets as well as add new ones to create a peninsula and more storage. I was surprised at how many styles and colors there were to choose from. They were great to work with and I am very pleased with the end result.",
        author: "Kristen",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["insulation"],
    company: "Energy 360",
    number: "630-422-1941",
    website: "www.energy360solutions.com",
    website_display: "rgy360solutions.com",
    testimonials: [
      {
        body: "When I first moved into my new home, my upstairs bedroom was either freezing cold or unbearably hot, I could feel the air coming through the walls! Energy360 came out and gave me a free evaluation and I used them to improve existing insulation in the attic, seal/insulate the pony walls in the bedroom and seal the air gaps along the foundation in my crawl space and basement. What a difference! They also submitted a rebate to ComEd for me and certified my home with an efficient energy rating.",
        author: "Kristen",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["driveway", "paving", "sealcoating"],
    company: "Murphy Sealcoating",
    number: "630-654-8242",
    website: "http://www.murphysealcoating.com",
    website_display: "murphysealcoating.com",
    testimonials: [
      {
        body: "Responsive and reasonably priced. Sealed my driveway fast and without a big mess.",
        author: "Kristen",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["floors", "hardwood", "flooring"],
    company: "Brookfield Floor Sanding",
    number: "708-715-6500",
    testimonials: [
      {
        body: "I had wall to wall carpeting removed and the oak floors beneath sanded and refinished. They also installed new plank flooring on the main floor. The team of guys were all very personable, on time and did a great job!",
        author: "Kristen",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["pest control"],
    company: "Grizz Pest Control",
    name: "Andy",
    number: "708-800-8660",
    website: "http://www.grizzpest.com",
    website_display: "grizzpest.com",
    testimonials: [
      {
        body: "I had Andy come out to deal with my ant problem. He’s a very nice guy and I appreciated that, unlike other pest control companies, there was no hard sell for a long-term treatment contract. While he’s probably not the cheapest you can find, I like to support local businesses and it was worth it — I haven’t seen any ants since!",
        author: "Kristen",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["gutters", "siding"],
    company: "Pro Exterior Works",
    name: "John",
    number: "773-791-3500",
    testimonials: [
      {
        body: "John is a very nice, hard working guy. He’s great if you need any gutter work or have a small siding job. Because he’s a one man operation, larger projects can take a long time though. I used him to reside my garage and replace/repair/redirect gutters.",
        author: "Kristen",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["hvac", "heating", "ventilation", "air", "conditioning", "cooling"],
    company: "Builders Choice HVAC",
    name: "Dennis Simmons",
    number: "708-710-5163",
    email: "builderschoicehvac@yahoo.com",
    website: "http://www.builderschoicehvac.com",
    website_display: "builderschoicehvac.com",
    testimonials: [
      {
        body: "Fair, reasonable, accurate and professional. He may even walk you through simple fixes.",
        author: "Mike",
        city: "La Grange"
      },
      {
        body: "Dennis is honest, fair and reasonably priced. Quality work delivered in a timely way.",
        author: "Mike",
        city: "La Grange"
      },
      {
        body: "Extremely helpful, honest, and empathetic. Dennis provided us with options that fit our budget, was on time, and completed the work on schedule.",
        author: "Pete",
        city: "Brookfield"
      }
    ]
  },
  {
    tags: ["moving", "movers"],
    company: "Burrows Moving Company",
    number: "773-274-5500",
    testimonials: [
      {
        body: "We hired Burrows specifically because we needed our piano moved. They were on time, worked quickly and made sure all items were placed in specific rooms, as indicated. Overall, they made for a seamless moving day and we would certainly hire their service again.",
        author: "Krystle",
        city: "Burr Ridge"
      }
    ]
  },
  {
    tags: ["tree", "removal", "tree removal", "landscapers"],
    company: "Best Budget Tree Service",
    number: "630-880-9663",
    testimonials: [
      {
        body: "The owner came out quickly to provide an estimate. Our tree & stump removal was completed in a few short hours.  The cost was reasonable in comparison to other companies that quoted the same project for us.",
        author: "Krystle",
        city: "Burr Ridge"
      }
    ]
  },
  {
    tags: ["tree", "removal", "tree removal", "landscapers"],
    company: "Brookfield Tree Service",
    number: "708-485-5181",
    website: "https://www.brookfieldtreeservices.net",
    website_display: "brookfieldtreeservices.net",
    testimonials: [
      {
        body: "Brookfield Tree Service was fast, reasonably priced, and provided excellent service.  We asked them to do more than was originally quoted, and they were very accommodating and professional.  Peter, the owner, supervises every job and the crew cleaned up everything when they were done.  They even left us with some firewood at our request.",
        author: "Chet",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["heating", "cooling", "air", "conditioning", "hvac"],
    company: "Freedom Heating & Cooling",
    number: "847-288-1788",
    testimonials: [
      {
        body: "I've dealt with them a couple of times. They have been prompt, do thorough work and do a good job explaining the problem.",
        author: "John",
        city: "Willowbrook"
      }
    ]
  },
  {
    tags: ["painting", "painters"],
    company: "O'Connor Painting",
    name: "Michael O'Connor",
    number: "630-234-7414",
    email: "oconnorpainting@live.com",
    testimonials: [
      {
        body: "Mike is such a nice guy, and reasonably priced. He flawlessly matched the stain of our windows in our old house, and made our new house look amazing in only 2 days (painted the WHOLE house interior). His crew was prompt and cleaned up everything!",
        author: "Jennifer",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["movers", "moving"],
    company: "Pack-Men Movers",
    website: "https://pack-menmovers.com",
    website_display: "pack-menmovers.com",
    testimonials: [
      {
        body: "Pack-Men movers are super inexpensive- used them to pack up our belongings and put them in a POD, then unpack and place in the house. They were quick, careful, and honest. Nothing broke!",
        author: "Jennifer",
        city: "Western Springs"
      },
      {
        body: "Pack Men Movers were great!!  The guys were nice, called before they came, on time, very careful...thumbs up for them!!!",
        author: "Anne",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["roofers", "roofing"],
    company: "Licitra Roofing",
    website: "http://www.licitraroofing.com",
    website_display: "licitraroofing.com",
    testimonials: [
      {
        body: "Licitra roofers- extremely nice and did an amazing job on our flat roof. I think they are a little more expensive, but the owner lives in Western Springs, so it is nice to support them.",
        author: "Jennifer",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["electrical", "electrician"],
    company: "Gianni Marinelli",
    name: "Gianni Marinelli",
    number: "312-208-3947",
    email: "xgiannim78@yahoo.com",
    testimonials: [
      {
        body: "Gianni is a licensened electrician who is also a Chicago fireman.  He installed 20+ lights in our basement. He was helpful guiding us on the best layout. He is reasonable and great to work with. We highly recommend him.",
        author: "Teresa",
        city: "Park Ridge"
      }
    ]
  },
  // {
  //   tags: ["plumbing", "plumber"],
  //   company: "Chris Potter",
  //   name: "Chris Potter",
  //   number: "630-207-4784",
  //   testimonials: [
  //     {
  //       body: "Chris is responsive, reasonable, and friendly!",
  //       author: "Ann",
  //       city: "La Grange"
  //     }
  //   ]
  // },
  {
    tags: ["tree", "landscaping", "removal", "landscapers"],
    company: "Tulip Tree Landscaping",
    name: "Matt",
    number: "708-670-9592",
    testimonials: [
      {
        body: "The owner, Matt, is easy to communicate with, affordable, and his crew does nice work.",
        author: "Ann",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["plaster", "plastering", "stucco"],
    company: "Kelly Plastering",
    name: "Dave Kelly",
    email: "kellyplastering@hotmail.com",
    testimonials: [
      {
        body: "A local guy who does a fantastic job on stucco on old houses.",
        author: "Jennifer",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["cleaning", "softwash", "powerwashing", "pressure"],
    company: "Chicagoland Softwash",
    name: "Tommy Jurek",
    email: "tjurek@chicagolandsoftwash.com",
    testimonials: [
      {
        body: "He's the one who made our house sparkle before selling. Offered a 3-year warranty against mold and algae growth. Took care around plants and landscaping, too.",
        author: "Jennifer",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["mortgage", "loan"],
    company: "Wintrust Mortgage",
    name: "Jill Diethelm",
    number: "708-743-5626",
    email: "jdiethelm@wintrustmortgage.com",
    testimonials: []
  },
  {
    tags: ["mortgage", "loan"],
    company: "A and N Mortgage",
    name: "Mike McNamara",
    number: "708-288-3377",
    email: "mikem@anmtg.com",
    testimonials: []
  },
  // {
  //   tags: ["inspection", "inspector", "home"],
  //   company: "Classic Home Inspection",
  //   name: "Mark Havens",
  //   number: "708-955-3745",
  //   email: "mark@classichomeinspection.com",
  //   testimonials: [
  //     {
  //       body: "We recommend Mark Havens as a highly professional and knowledgeable inspector.  When he conducted an inspection on our house he was laser focused the entire time, able to find anomalies we overlooked.  Mark was very thorough with his explanations, able to explain everything in simplistic terms.  We would use Mark's service again in the future, along with recommending him to our friends and family.",
  //       author: "Bryan",
  //       city: "Westchester"
  //     }
  //   ]
  // },
  {
    tags: ["general contractors", "contractor", "general", "contractors"],
    company: "Peter Kavsky",
    name: "Peter Kavsky",
    number: "630-864-7857",
    testimonials: [
      {
        body: "Absolutely a world class contractor.",
        author: "Dave",
        city: "Burr Ridge"
      }
    ]
  },
  {
    tags: ["pressure", "washing", "powerwashing", "cleaning"],
    company: "Powerhouse Pressure Washing",
    number: "708-714-6308",
    testimonials: [
      {
        body: "We hired Jason to power wash the exterior of our house in preparation for repainting. Jason was easy to communicate with, give us a fair price, was able to complete the job on short notice, and most importantly, did an excellent job. He was thorough and even picked up the paint chips off of the lawn/patio that came off with power washing. I would highly recommend Jason service and will use him again for any future power washing needs.",
        author: "Julie",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["chimney", "sweep"],
    company: "Cinderfella Chimney Sweep",
    number: "773-238-9700",
    testimonials: [
      {
        body: "Cinderfella swept our chimney. He was much cheaper than other quotes and did a great job. He was punctual, very clean/ tidy, friendly, and took the time to educate us about the general care of our fireplace and chimney. We would highly recommend him and his team!",
        author: "Julie",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["garage", "door"],
    company: "Andy's Garage Door Service",
    number: "773-774-3667",
    testimonials: [
      {
        body: "Andy installed a new garage door opener for us. He was reasonably priced and punctual. We are happy with his work.",
        author: "Julie",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["movers", "moving"],
    company: "2 Guys and a Van, Inc",
    number: "312-857-4897",
    testimonials: [
      {
        body: "Affordable and did a great job. Planning on using them again in December.",
        author: "Rob",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["garage", "door", "garage door"],
    company: "A Completed Door",
    number: "888-768-3100",
    website: "http://acompletedoor.com",
    website_display: "acompletedoor.com",
    testimonials: [
      {
        body: "Repaired broken springs on 2 of our garage doors. Great work.",
        author: "Rob",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["junk", "removal", "junk removal"],
    company: "1-800-GOT-JUNK",
    website: "https://www.1800gotjunk.com/us_en/locations/junk-removal-chicago",
    website_display: "Website",
    number: "1-800-468-5865",
    testimonials: [
      {
        body: "Quick and efficient, good for fast junk removal. Probably twice as expensive as renting a dumpster, so paying for speed and convenience.",
        author: "Rob",
        city: "La Grange"
      },
      {
        body: "Absolutely loved them!  Courteous, on time, easy to work with and reasonable!",
        author: "Jean",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["gutter", "cleaning"],
    company: "Leafguard",
    number: "630-920-1651",
    testimonials: [
      {
        body: "Expensive, but they work and you never have to get on a ladder again to clean the gutters...",
        author: "Rob",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["roofer", "roofing"],
    company: "Ciraulo & Sons Roofing",
    number: "708-354-4653",
    testimonials: [
      {
        body: "Used them for our home on 1113 S Waiola. Hand nailed, no issues for the 10 years we were there.",
        author: "Rob",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["electrician", "electrical"],
    company: "Highlight Chicago",
    number: "773-580-4371",
    website: "http://www.highlightschicago.com",
    website_display: "highlightschicago.com",
    testimonials: [
      {
        body: "After may renovations on my 1929 house were completed, I realized that we need some additional outlets and lighting. Boz electric responded right away, came out and gave us a great quote and started the next day. They had to channel a line from an outlet which required patching, mudding, sanding and painting.  They did an amazing job. I can’t tell where they did the work. They also did the electric in our basement that we just had finished. We couldn’t be happier with their professionalism and the quality of their work.",
        author: "Kelly",
        city: "Orland Park"
      }
    ]
  },
  {
    tags: ["clean", "cleaner", "carpet", "steam"],
    company: "Dunne Carpet Cleaning",
    number: "708-345-0922",
    website: "http://www.dunnecleaning.com/",
    website_display: "dunnecleaning.com",
    testimonials: [
      {
        body: "They did a beautiful job!   They scrub the carpet first, then steam clean it.  The company is family owned so you get a personal touch.  Very reasonable.",
        author: "Mike",
        city: "La Grange Park"
      }
    ]
  },
  {
    tags: ["painting", "paint", "painters", "decorating"],
    company: "C.A.T. Painting and Decorating",
    name: "Peter Mitrega",
    number: "708-351-0997",
    testimonials: [
      {
        body: "Peter did a wonderful job painting the first floor of our home.  He is extremely neat and has great attention to detail.  He is also efficient and fair in his pricing.  We will definitely call him again for our next painting project.  We highly recommend his services!",
        author: "Allison C",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["plumbing", "plumber"],
    company: "Palos Plumbing",
    name: "Thomas",
    number: "708-307-5636",
    testimonials: [
      {
        body: "Reliable, affordable and friendly.",
        author: "Mike",
        city: "La Grange"
      },
      {
        body: "Very liable, came during the arctic freeze when our pipes burst and charged me a very fair rate.",
        author: "Steve",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["handyman"],
    company: "Alex Carpenter",
    number: "773-230-0355",
    testimonials: [
      {
        body: "Dry walled and painted our basement. Very reasonable price was great and he was very reliable.",
        author: "Steve",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["window", "washing"],
    company: "L. A. McMahon",
    number: "800-820-6155",
    testimonials: [
      {
        body: "Has a big team and could come quickly. Good and not very expensive.",
        author: "Joan",
        city: "Hinsdale"
      }
    ]
  },
  {
    tags: ["plumber", "plumbing"],
    company: "Allmark Plumbing",
    // name: "",
    number: "630-379-9712",
    testimonials: [
      {
        body: "Came promptly and was courteous. Figured out how to make our gas fireplace work. Replaced sump pumps as well.",
        author: "Joan",
        city: "Hinsdale"
      }
    ]
  },
  {
    tags: ["moving", "movers"],
    company: "A Available Moving Co.",
    // name: "",
    number: "708-788-6683",
    testimonials: [
      {
        body: "Reasonably priced, lives locally in La Grange Park.",
        author: "Joan",
        city: "Hinsdale"
      }
    ]
  },
  {
    tags: ["carpet", "cleaner"],
    company: "Saban's Carpet Care",
    // name: "",
    number: "800-734-3750",
    testimonials: [
      {
        body: "Reasonable, local and cleans wall to wall carpeting. Also has a brother that will pick up and bring back throw rugs.",
        author: "Joan",
        city: "Hinsdale"
      }
    ]
  },
  {
    tags: ["appliance", "repair"],
    company: "Spin Doctor",
    // name: "",
    number: "708-715-7746",
    testimonials: [
      {
        body: "He was very good and can fix anything that spins (dishwasher, washer, dryer). Came the day before Christmas! Very responsive and knowledgeable.",
        author: "Joan",
        city: "Hinsdale"
      }
    ]
  },
  // {
  //   tags: ["hvac", "heating", "cooling", "air conditioning", "ac"],
  //   company: "Cozy Heating and Air Conditioning",
  //   // name: "",
  //   number: "708-514-1591",
  //   testimonials: [
  //     {
  //       body: "Sold and serviced our heating and a/c for 20 years! Dependable, knowledgeable and reasonable.",
  //       author: "Joan",
  //       city: "Hinsdale"
  //     }
  //   ]
  // },
  {
    tags: ["handyman", "painter", "painting", "carpentry"],
    company: "Andy Dluski Handyman",
    // name: "",
    number: "708-805-0301",
    testimonials: [
      {
        body: "Hard worker and does good work. Did many odds and ends when we were selling.",
        author: "Joan",
        city: "Hinsdale"
      }
    ]
  },
  {
    tags: ["remodeling", "contractor", "contractors", "general", "general contractors"],
    company: "Vancouver Remodeling",
    name: "Matthew Szlachetko",
    number: "773-818-6067",
    testimonials: [
      {
        body: "Remodeled our bathroom and did a very good job.",
        author: "Joan",
        city: "Hinsdale"
      }
    ]
  },
  {
    tags: ["paint", "painters"],
    company: "Dragan Pljevaljcic",
    // name: "Matthew Szlachetko",
    number: "773-744-1452",
    testimonials: [
      {
        body: "Very efficient - came with 3 men, could work around other contractors, easy to schedule.",
        author: "Karen",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["electrician", "electric"],
    company: "Zachary Installations",
    name: "Zach Verdon",
    number: "630-865-8906",
    email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "Zach is careful in his electrical work. Creative and will coach you if you ask!",
        author: "Mike",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["wood", "floors"],
    company: "Ideal Floor - Wood Floors and Sanding",
    name: "Larry Ackley",
    number: "630-910-4947",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "Reasonable, ethical, straight forward, cleans up after themselves, would definitely use them again.",
        author: "Jane",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["painting", "painters"],
    company: "iPainting",
    name: "Abi",
    number: "224-634-0048",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "FANTASTIC painters. Neat, efficient and super affordable.  I've already recommended them to many of my friends and all gave rave reviews.  They painted my kitchen cabinets and they turned out amazing.  Never would have known they were painted.",
        author: "Evie",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["landscapers", "landscaping"],
    company: "Laslo Landscaping",
    name: "James Laslo",
    website: "http://www.laslolandscape.com/",
    website_display: "laslolandscape.com",
    // number: "224-634-0048",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "When we contacted James, he promptly came out to give us a quote. He was willing to work within our budget and his crew created a lovely flagstone landing, stairs, and stacked flagstone planter bed. They carefully installed sod and made our yard look lovely!",
        author: "Jen",
        city: "Downers Grove"
      }
    ]
  },
  {
    tags: ["contractor", "contractors", "general contractors", "garage"],
    company: "Blue Sky Builders",
    // name: "James Laslo",
    website: "http://www.blueskybuilders.com",
    website_display: "blueskybuilders.com",
    // number: "224-634-0048",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "Blue Sky built a beautiful garage to replace our 1920's garage that was falling over. They designed a garage with details to match our historic home, and worked with us carefully to be sure the finished product was exactly what we wanted.",
        author: "Jen",
        city: "Downers Grove"
      }
    ]
  },
  {
    tags: ["landscaping", "tree", "trimming"],
    company: "Mike's Tree Service",
    // name: "James Laslo",
    website: "https://www.yelp.com/biz/mikes-tree-service-downers-grove",
    website_display: "Yelp",
    // number: "224-634-0048",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "We had a giant Siberian Elm tree that was very close to our house and our neighbor's house; it was in very bad shape and Mike and his crew carefully removed it without a hitch. Mike is incredibly friendly and easy to work with; we highly recommend his tree service!",
        author: "Jen",
        city: "Downers Grove"
      }
    ]
  },
  {
    tags: ["landscaping", "tree", "trimming"],
    company: "S.W.A.T. Radon",
    // name: "James Laslo",
    website: "http://www.swat-radon.com/",
    website_display: "swat-radon.com",
    number: "800-667-2366",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "I would recommend S.W.A.T environment for radon mitigation.",
        author: "Debbie",
        city: "Willowbrook"
      }
    ]
  },
  {
    tags: ["landscaping", "paver", "patio", "stone"],
    company: "Prairie Path Pavers",
    // name: "James Laslo",
    website: "http://www.prairiepathgroup.com/",
    website_display: "prairiepathgroup.com",
    number: "708-354-2182",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "I used their services in Western Springs over 15 years ago after learning that they have been used by a large percentage of Western Springs residents who have any paver work done whether driveways, patios, or sidewalks.  After recently moving to Westmont, we again used their services to put in a patio.  Both times I have been quite pleased with their process, patience with me, design and flexibility, removal of a deck, and the final installation.",
        author: "Carol",
        city: "Westmont"
      }
    ]
  },
  {
    tags: ["door", "window", "doors", "windows"],
    company: "Next Door & Window",
    name: "Ron Burns",
    website: "https://www.nextdoorandwindow.com/",
    website_display: "nextdoorandwindow.com",
    number: "630-323-6398",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "We just had our windows replaced. We highly recommend Next Door and Windows in Burr Ridge. Ron Burns and his crew were extremely professional, timely and very conscientious in their work. Their customer service was top notch...can’t say enough good things about them!",
        // author: "Carol",
        // city: "Westmont"
      }
    ]
  },
  {
    tags: ["general contractors", "contractor", "general", "contractors"],
    company: "LaRocca Builders",
    name: "Vince Larocca",
    // website: "https://www.nextdoorandwindow.com/",
    // website_display: "nextdoorandwindow.com",
    number: "630-774-4030",
    // email: "zacharyinstallations@gmail.com",
    testimonials: [
      {
        body: "Our mother in law had introduced Vince to us as he had done some work for her in Lagrange. He is a really nice, honest guy to work with. He recently completed a vinyl plank flooring project for us and it came out great. Vince says he likes to always try and overdeliver on his jobs and he came thru on that for us. Would definitely recommend for a wide variety of general construction work, and we will likely be using again for our next bathroom remodeling project.",
        author: "Dan S.",
        city: "Brookfield"
      }
    ]
  },
  {
    tags: ["concrete", "driveway", "road", "pave"],
    company: "La Grange Custom Concrete",
    name: "John Jamrozik",
    // website: "https://www.nextdoorandwindow.com/",
    // website_display: "nextdoorandwindow.com",
    number: "708-354-2445",
    testimonials: [
      {
        body: "We used John Jamrozik, owner of La Grange Custom Concrete, for two jobs at our house. Once to convert our driveway from asphalt to concrete and once to replace portions of our walks.  Both times he did an excellent job. We are very pleased with his workmanship. He did what he said he would do.",
        author: "Erek E.",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["painter", "paint", "painters"],
    company: "Konrad’s Paint Effects",
    name: "Konrad Stankiewicz",
    // website: "https://www.nextdoorandwindow.com/",
    email: "konradpfx@sbcglobal.net",
    number: "708-246-1768",
    testimonials: [
      {
        body: "Konrad and his team were not only meticulous, they painted our rooms thouroughly - removing, then replaceing, all hardware, light fixtures, etc.  Everything looks brand new!",
        author: "Carolyn",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["handyman", "handy"],
    company: "Trejo Service",
    name: "Maurilio Trejo",
    // website: "https://www.nextdoorandwindow.com/",
    // email: "konradpfx@sbcglobal.net",
    number: "708-466-0309",
    testimonials: [
      {
        body: "Maurilio and his son have helped me with a large variety of things at my house, but most recently they custom built lockers in my garage to create a mudroom feel.  They are awesome!",
        author: "Tricia",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["carpet", "hvac", "cleaning", "cleaners"],
    company: "Really Kleen Air Duct, Carpet & Upholstery Cleaning, Inc.",
    number: "630-254-4768",
    testimonials: [
      {
        body: "We've used Bob several times for carpet and aird duct cleaning. He's very reasonable. He's a one man show out of Bartlett. He's not very fast and can be chatty, but he does a good job and I trust him. I was going to have him clean our air ducts again last year and he told me that they didn't need it yet, so he's honest, too!",
        author: "Allison",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["painting", "painters"],
    company: "Kwak Brothers",
    name: "Carl Kwak",
    number: "630-774-1678",
    email: "kwakbrothers@sbcglobal.net",
    testimonials: [
      {
        body: "They are awesome. They just stained both sides of our fence this year as well.",
        author: "Brad",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["restoration", "restore"],
    company: "Atlas Restoration",
    name: "Jim Mitros",
    number: "847-415-9600",
    email: "jmitros@atlasrestoration.com",
    website: "http://www.atlasrestoration.com/",
    website_display: "atlasrestoration.com",
    testimonials: [
      {
        body: "They did our draintile work a few weeks ago. Also an awesome job and at a reasonably affordable price.",
        author: "Brad",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["organizer", "organize"],
    company: "Peace of Mind Professional Organizing LLC",
    name: "Colleen Klimczak",
    // number: "847-415-9600",
    email: "colleen@peaceofmindpo.com",
    website: "https://peaceofmindpo.com",
    website_display: "peaceofmindpo.com",
    testimonials: [
      {
        body: "A true professional - ontime, responsive, incredibly knowledgeable, not pushy or judgemental. Invaluable in a move and new house. Purging before packing moves very quickly and much less painful when you have a leader structuring the process. And in the new house sets up the kitchen and closets in the most functional way. No more trial and error!",
        author: "Alison",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["electrician", "electrical"],
    company: "Bill Fritz",
    // name: "Colleen Klimczak",
    number: "708-822-8723",
    email: "arcnspark1@aol.com",
    // website: "https://peaceofmindpo.com",
    // website_display: "peaceofmindpo.com",
    testimonials: [
      {
        body: "Bill did a great job. Small side job (added outlets in basement). Fast and affordable (half of another quote I got).  Would definitely recommend.",
        author: "Rob",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["electrician", "electrical"],
    company: "Duplex Electric",
    // name: "Colleen Klimczak",
    number: "708-387-9400",
    // email: "arcnspark1@aol.com",
    // website: "https://peaceofmindpo.com",
    // website_display: "peaceofmindpo.com",
    testimonials: [
      {
        body: "Greg and Steve of Duplex Electric are great! I found a recommendation for them on next-door.com, and have since had them out to fix a few different issues. Both times I've called they have responded within hours to my voicemail messages and have come out that day or the next day. They first came out to replace a basement ceiling light. Through this they discovered that the switch was improperly wired. They explained clearly what the issue was, and fixed the switch and replaced the fixture quickly. The second time they came out to install two ceiling fixtures and to replace our kitchen outlets with GFCI outlets. Both times they explained to me clearly what the issues were, worked with safety precautions, arrived on time, and charged a reasonable amount. They are super professional, very knowledgeable, and on top of that were friendly with my crazy dogs - I will for sure call them the next time we need anything electrical fixed. Thank you, Greg and Steve!",
        author: "Rob",
        city: "La Grange"
      }
    ]
  },
  {
    tags: ["electrician", "electrical"],
    company: "Eco-lectric",
    number: "847-372-3516",
    testimonials: [
      {
        body: "Mike was the electrician for my basement remodel.  He was extremely conscientious and helpful in making critical recommendations - things I wouldn't have even thought of.  Excellent work and really nice guy!",
        author: "Debbie",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["gutter", "cleaning", "windows"],
    company: "Bubbles Window Cleaners and Gutter Cleaning",
    number: "1-800-640-9170",
    testimonials: [
      {
        body: "Bubbles provides a great service and follow up along with very competitive pricing for both window cleaning and gutter cleaning.  I highly recommend them.",
        author: "Barry",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["water", "proofing", "waterproof", "waterproofing"],
    company: "Accu-Dry Basement Waterproofing and Concrete Crawlspace",
    // name: "Colleen Klimczak",
    number: "630-663-4598",
    // email: "arcnspark1@aol.com",
    website: "https://www.accu-drybasements.com",
    website_display: "accu-drybasements.com",
    testimonials: [
      {
        body: "I had seepage in my crawlspace and Accu-Dry fixed it at a reasonable cost, in fact at the low end of the estimates I gathered.  I would recommend this company.",
        author: "Sandy",
        city: "Westmont"
      }
    ]
  },
  {
    tags: ["wood", "floors", "wood floor", "hardwood", "refinish", "floor"],
    company: "!DC FLOORS!inc",
    name: "David Camacho",
    number: "773-600-0978",
    // email: "arcnspark1@aol.com",
    // website: "https://www.accu-drybasements.com",
    // website_display: "accu-drybasements.com",
    testimonials: [
      {
        body: "We collected 3 quotes, these guys were the middle and charged us $5,000 to sand, refinish & add wood floors to match in our family room. They were a recommendation from an associate, we were very happy with their work.",
        author: "Jen",
        city: "Western Springs"
      }
    ]
  },
  {
    tags: ["hvac", "heat", "ac", "air conditioning", "furnace", "repair", "service"],
    company: "Tiger Heating & Air, Inc",
    // name: "David Camacho",
    number: "773-362-4400",
    // email: "arcnspark1@aol.com",
    website: "https://www.orlandparkairconditioningrepair.com",
    website_display: "tigerheatingandair.com",
    testimonials: [
      // {
      //   body: "We collected 3 quotes, these guys were the middle and charged us $5,000 to sand, refinish & add wood floors to match in our family room. They were a recommendation from an associate, we were very happy with their work.",
      //   author: "Jen",
      //   city: "Western Springs"
      // }
    ]
  },
]


export default vendors;