import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { toggleSideMenu, setContactModalOpen } from '../../actions/actions.export.js'

import Button from '../Button/Button.js'

import './Header.css';

class Header extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.state = {
      translucent: true
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSideMenuToggle = this.handleSideMenuToggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    // window.location.hash = ;

    if(window.scrollY > 0){
      this.setState({
        top: false
      });
    } else {
      this.setState({
        top: true
      });
    }
  }

  handleSideMenuToggle(){
    const { dispatch } = this.props;
    dispatch(toggleSideMenu())
  }


  render(){
    const { dispatch, blog } = this.props; 
    const state = this.state;

    let blogPostCount = Object.keys(blog.postCache).length;
    for(let i in blog.postCache){
      if(blog.postCache[i].categories.indexOf(13) > -1){
        blogPostCount--;
      }
    }

    return (
      <div className={"header "
       + (state.top && !this.props.scrolling ? "header-top" : "header-scrolling")
       + (this.props.overImage ? " header-over-image" : "")}>
        
          <div className="flex-split">
            <div className="list-left">
              <Link to="/" className="list-item brand-font logo-block">
                <span className="logo-wrapper">
                  <img src="/img/lpp.png" height="100%" width="100%"/>
                </span>
                <span className="lpp-info">
                  <strong>Lindsey Pann Paulus</strong><br/>
                  <nobr><strong>708-926-5034</strong> &nbsp;</nobr> <span className="lb-email">lindsey@lindseypaulus.com</span><br/>
                  <img src="/img/logo-lockup-horizontal.png" height="18px" className="list-item"/>
                </span>
              </Link>
            </div>
           
            <div className="list-right">
              <Link to="/listings" className="list-item d-none d-md-block underlined underlined-secondary">Listings</Link>
              <Link to="/search" className="list-item d-none d-lg-block underlined underlined-secondary"><nobr><i className="far fa-search mls-search-icon"/> MLS</nobr></Link>
              <Link to="/neighborhoods/la-grange" className="list-item d-none d-lg-block underlined underlined-secondary">Neighborhoods</Link>
              <Link to="/buyers" className="list-item d-none d-lg-block underlined underlined-secondary">Buyers</Link>
              <Link to="/sellers" className="list-item d-none d-lg-block underlined underlined-secondary">Sellers</Link>  
              <Link to="/#about" className="list-item d-none d-lg-block underlined underlined-secondary">About</Link>

              {
                blogPostCount > 0 &&
                <Link to="/blog" className="list-item d-none d-lg-block underlined underlined-secondary">Blog</Link>
              }
             
              <div className="dropdown show d-none d-lg-block">
                <nobr><a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="underlined underlined-secondary">Resources</span>
                </a></nobr>

                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                  <Link to="/client-resources/recommended-vendors" className="dropdown-item">Recommended Vendors</Link>
                  <Link to="/client-resources/decluttering-911" className="dropdown-item">Decluttering 911</Link>
                  <Link to="/client-resources/mortgage-calculator" className="dropdown-item">Mortgage Calculator</Link>
                  <Link to="/client-resources/net-sheet-calculator" className="dropdown-item">Net Sheet Calculator</Link>
                </div>
              </div>
              
              <div className="list-item d-none d-sm-block">
                <Button
                  display={<span>Contact</span>}
                  color="secondary"
                  onClick={(e)=>{ dispatch(setContactModalOpen({open: true})) }}
                />
              </div>
              <span className="list-item menu-btn d-xl-none d-lg-none d-md-block" onClick={this.handleSideMenuToggle}>
                <i className="fal fa-lg fa-fw fa-bars"/>
              </span>
            </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { globalReducer, blog } = state;

  return {
    globalReducer,
    blog
  }
}

export default connect(mapStateToProps)(Header);
