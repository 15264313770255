import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DOMPurify from 'dompurify'

import './BlogPost.css';

class BlogPost extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
  }

  render(){
    const { dispatch, post, blog } = this.props; 
    const state = this.state;


    function decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }


    if(!post){
      return <div className="blog-post">
        <span className="blog-post-category blog-post-category-loading">
          Loading...
        </span>
      </div>
    }

    return (
      <div className={"blog-post " + (this.props.excerpt ? "white-container" : "") }>
        <div className={"blog-post-header-content " + (this.props.excerpt ? "blog-post-mini-header" : "")}>
          {
            post.featuredMedia ?
            <Link to={"/blog/post/" + post.id}>
              <div className="blog-post-featured-image-wrapper">
                <img src={this.props.excerpt ? post.featuredMedia.media_details.sizes.thumbnail.source_url : post.featuredMedia.guid.rendered} className="blog-post-featured-image"/>
                <div className="blog-post-featured-image-gradient"></div>
                <div className="blog-post-featured-image-caption" dangerouslySetInnerHTML={{__html: post.featuredMedia.caption.rendered}}></div>
              </div>
            </Link>
            :
            <span/>
          }
          <div className="blog-post-title-and-date">
            {
              post.categories.map((c,i)=>{
                let cat;
                for(let j in blog.categories){
                  if(blog.categories[j].id === c){
                    cat = blog.categories[j];
                    break;
                  }
                }

                if(!cat) return;

                return <Link 
                  to={"/blog/posts/in/category/" + cat.slug} 
                  key={i} 
                  className={"blog-post-category " + (i < post.categories.length - 1 ? "blog-post-category-divider" : "")}
                  >
                    {cat.name}
                  </Link>;
              })
            }
            
            {
              this.props.excerpt ? 
              <h3 className="blog-post-title">
                <Link to={"/blog/post/" + post.id}>{decodeHtml(post.title.rendered)}</Link>
              </h3>
              :
              <h3 className="blog-post-title">{decodeHtml(post.title.rendered)}</h3>
            }

            <div className="list-left">
              <span className="blog-post-date list-item">{moment(post.date).format("dddd, MMMM Do YYYY, h:mm a")}</span>
            </div>
          </div>
        </div>
        <hr/>

        {
          this.props.excerpt ? 
          <div>
            <div className="blog-post-content" dangerouslySetInnerHTML={{__html: post.excerpt.rendered }}></div>
            <Link to={"/blog/post/" + post.id}>Keep reading...</Link>
          </div>
          :
          <div className="blog-post-content" dangerouslySetInnerHTML={{__html: post.content.rendered }}></div>
        }
        
        {
          (!this.props.excerpt && post.tags.length > 0) &&
          <div className="blog-post-tag-wrapper">
            <span className="blog-post-tags-label">TAGS:</span>
            {
              post.tags.map((c,i)=>{
                let tag;
                for(let j in blog.tags){
                  if(blog.tags[j].id === c){
                    tag = blog.tags[j];
                    break;
                  }
                }

                if(!tag) return;

                return <Link to={"/blog/posts/with/tag/" + tag.slug} key={i} className="blog-post-tag">{tag.name}</Link>;
              })
            }
          </div>
        }

        {
          !this.props.excerpt &&
          <div className="flex-split">
            {
              post.previous_post ? <Link to={"/blog/post/" + post.previous_post.id}>
                <i className="fas fa-angle-left"/> Previous Post
              </Link>
              :
              <span/>
            }
            {
              post.next_post ? <Link to={"/blog/post/" + post.next_post.id}>
                Next Post <i className="fas fa-angle-right"/> 
              </Link>
              :
              <span/>
            }
          </div>
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { blog } = state;

  return {
    blog
  }
}

export default connect(mapStateToProps)(BlogPost);
