import React, { Component } from 'react'
import shortid from 'shortid';

import './Button.css';

class Button extends Component {
  constructor(props){
    super(props);

    this.state = {
      id: shortid.generate(),
      display: props.display,
      disabled: props.disabled,
      thinking: props.thinking || false,
      size: props.size || 'medium',
      color: props.color,
      icon: props.icon,
      fixedWidth: props.fixedWidth,
      block: props.block
    }
  }

  componentWillReceiveProps(newProps){
    this.setState({
      display: newProps.display,
      disabled: newProps.disabled || false,
      thinking: newProps.thinking || false,
      size: newProps.size || 'medium',
      color: newProps.color,
      icon: newProps.icon,
      fixedWidth: newProps.fixedWidth,
      block: newProps.block
    })
  }

  render(){
    const state = this.state;

    var classes = (state.size === 'large' ? " button-lg" : "") + 
        (state.size === 'medium' ? " button-md" : "") + 
        (state.size === 'small' ? " button-sm" : "") + 
        (state.size === 'thin' ? " button-thin" : "") + 
        (state.fixedWidth ? " fixed-width" : "") + 
        (state.color ? (" button-" + state.color) : "") +
        (state.block ? " button-block" : "") + 
        (this.props.dropdown ? " button-dropdown" : "");

    return <div className={"button " + (state.disabled ? 'button-disabled ' : '') + (state.thinking ? " button-thinking" : "")}>
        { this.props.submit && <button type="submit" style={{display: 'none'}}/>}
        {
          state.thinking ? (
            <div className={"button-wrapper " + classes} onClick={this.props.onClick} onMouseDown={this.props.onMouseDown}>
              <span style={{opacity: 0}}>{state.display}</span>
              <div className="thinking">
                <i className="fas fa-spinner-third fa-spin fa-lg"/>
              </div>
            </div>
          ) : (
            state.icon ? (
              <div className={"button-wrapper " + classes} key={shortid.generate()} onClick={this.props.onClick} onMouseDown={this.props.onMouseDown}>
                <span className="button-display">{state.display}</span>
                <i className={"button-icon " + state.icon}/>
              </div>
            ) : (
              <div className={"button-wrapper " + classes} onClick={this.props.onClick} onMouseDown={this.props.onMouseDown}>
                <span>{state.display}</span>
              </div>
            )
          )
        }
        {
          this.props.dropdown && <div className="dropdown show">
            <a className={"dropdown-toggle button-dropdown-arrow " + classes} href="#" role="button" id={"btn-" + state.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {this.props.dropdownIcon ?  this.props.dropdownIcon : <i className="fas fa-caret-down"/>}
            </a>

            <div className="dropdown-menu dropdown-menu-right" aria-labelledby={"btn-" + state.id}>
              {this.props.dropdown}
            </div>
          </div>
        }

    </div>
  }
}

export default Button;