import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Button from '../Button/Button.js'
import CustomField from '../CustomField/CustomField.js'


import { tryToContact } from '../../actions/actions.export.js'

import './ContactForm.css';


class ContactForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.state = {
      name: props.globalReducer.contactForm.name || '',
      email: props.globalReducer.contactForm.email || '',
      phone: props.globalReducer.contactForm.phone || '',
      message: props.globalReducer.contactForm.message || ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(newprops){
    this.setState({
      name: newprops.globalReducer.contactForm.name,
      email: newprops.globalReducer.contactForm.email,
      phone: newprops.globalReducer.contactForm.phone,
      message: newprops.globalReducer.contactForm.message
    })
  }


  handleSubmit(e){

    
    const { dispatch, globalReducer, realtor } = this.props; 

    window.grecaptcha.ready(()=>{
      // console.log('ready');
      window.grecaptcha.execute('6LckrsgUAAAAAMerv6BTpKThJezYtP97x7ZZliTp', {action: 'homepage'}).then((token)=>{
        // console.log(token);

        var l = false;

        for(var i in realtor.listingDetailCache){
          if(realtor.listingDetailCache[i].id == globalReducer.contactModal.listing_id){
            l = realtor.listingDetailCache[i];
            break;
          }
        }

        dispatch(tryToContact({
          token: token,
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
          listing_id: globalReducer.contactModal.listing_id || "",
          listing_address: l ? l['Street Number'] + ' ' + l['Street Name'] +  (l['Unit Number'] ? (' #' + l['Unit Number']) : "") : ""
        }))

      })
    });
    e.preventDefault();
  }

  render(){
    const { dispatch, globalReducer } = this.props; 
    const state = this.state;

    return (
      <div className="register-form">

        <form name="ContactForm" onSubmit={this.handleSubmit}>

          <CustomField 
            type="text"
            name="display_name"
            placeholder="your name"
            label="Name"
            value={this.state.name}
            onChangeEvent={(e) => {this.setState({name: e}); }}
            required={true}
            setPrestine={globalReducer.successfulContact}
          />
          <CustomField 
            type="email"
            name="email"
            placeholder="your email address"
            label="Email"
            value={this.state.email}
            onChangeEvent={(e) => {this.setState({email: e}); }}
            required={true}
            setPrestine={globalReducer.successfulContact}
          />
          <CustomField 
            type="tel"
            name="phone"
            placeholder="your phone number"
            label="Phone Number"
            value={this.state.phone}
            onChangeEvent={(e) => {this.setState({phone: e}); }}
            required={true}
            setPrestine={globalReducer.successfulContact}
          />
          <CustomField
            type="text"
            name="message"
            placeholder="How can I help you?"
            label="Message"
            minRows={2}
            multipleRows={true}
            value={this.state.message}
            required={true}
            setPrestine={globalReducer.successfulContact}
            onChangeEvent={(e) => {this.setState({message: e}); }}
          />
          
          <div className="text-center">
            <Button 
              submit={true} 
              onClick={this.handleSubmit} 
              display={<span>Send <i className="fas fa-paper-plane"/></span>}
              size="large" 
              color="primary" 
              disabled={(this.state.name == '' || this.state.email == '' || this.state.phone == '' || this.state.message == '')}
            />
          </div>
        </form>
        <br/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { globalReducer, realtor } = state;

  return {
    globalReducer, realtor
  }
}

export default connect(mapStateToProps)(ContactForm);
