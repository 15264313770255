import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ListingRow from '../components/ListingRow/ListingRow.js'
import CustomField from '../components/CustomField/CustomField.js'

import vendors from '../vendor_data.js';

class VendorsRoute extends Component {
  constructor(props){
    super(props)

    this.state = {
      vendor_search: ""
    }
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    vendors.sort(function(a, b){
      if(a.company > b.company) return 1;
      if(a.company < b.company) return -1;
    });

    var found_vendors = [];

    for(var i in vendors){
      var v = vendors[i];
      var tagged = false;
      for(var t in v.tags){
        if(v.tags[t].indexOf(state.vendor_search.toLowerCase()) > -1){
          tagged = true;
          break;
        }
      }
      if(!tagged && v.company.toLowerCase().indexOf(state.vendor_search.toLowerCase()) == -1) continue;

      found_vendors.push(v);
    }

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-tools fa-fw secondary-header-icon"></i>&nbsp;
                Recommended Vendors
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{marginTop: -10}}>
        <div className="row">
          <div className="col-md-12">
            <p className="lead" style={{marginBottom: 0}}>
              A list compiled from my clients' successful experiences with local vendors
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <CustomField
              type="text"
              name="vendor_search"
              placeholder="Search"
              description={
                state.vendor_search == "" ? (<span>Search by category or name.</span>) :
                (<span className="flex-split"><span>showing {found_vendors.length} of {vendors.length} vendors</span> <span className="clickable-field-description" onClick={(e) => {this.setState({vendor_search: ""}); }}>clear search</span></span>)
              }
              autoUpdate={true}
              value={this.state.vendor_search}
              onChangeEvent={(e) => {this.setState({vendor_search: e}); }}
              icon="search"
            />
          </div>
          <div className="col-lg-9">
            <div className="list-left vendor-icon-list">
              <div className="list-item">
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'movers'}); }}>
                  <i className="fal fa-people-carry fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'contractor'}); }}>
                  <i className="fal fa-tools fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'landscapers'}); }}>
                  <i className="fal fa-tree-alt fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'electrician'}); }}>
                  <i className="fal fa-plug fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'plumber'}); }}>
                  <i className="fal fa-toilet fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'hvac'}); }}>
                  <i className="fal fa-temperature-hot fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'pest control'}); }}>
                  <i className="fal fa-bug fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'loan'}); }}>
                  <i className="fal fa-file-invoice-dollar fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'cleaning'}); }}>
                  <i className="fal fa-moon-stars fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'roof'}); }}>
                  <i className="fal fa-car-garage fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'painter'}); }}>
                  <i className="fal fa-paint-roller fa-fw fa-2x"/>
                </span>
                <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'waterproofing'}); }}>
                  <i className="fal fa-house-flood fa-fw fa-2x"/>
                </span>
                {/*
                  <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'chimney sweep'}); }}>
                    <i className="fal fa-chimney fa-fw fa-2x"/>
                  </span>
                  <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'cabinet'}); }}>
                    <i className="fal fa-cabinet-filing fa-fw fa-2x"/>
                  </span>
                  <span className="list-item vendor-icon" onClick={(e) => {this.setState({vendor_search: 'junk removal'}); }}>
                    <i className="fal fa-dumpster fa-fw fa-2x"/>
                  </span>
                */}
              </div>
            </div>
          </div>
        </div>
        <hr/>
        {
          found_vendors.length === 0 ? (
            <div className="row">
              <div className="col-md-12">
                <p className="lead">No vendors found, please try searching something else!</p>
              </div>
            </div>
          ) 
          :
          <div className="card-columns">
            {
              found_vendors.map(function(v, i){
                return <div className="card vendor" key={"v-" + i}>
                  <div className="vendor-company"><strong className="text-lpp-primary">{v.company}</strong></div>
                  <div className="vendor-detail"><strong>{v.number}</strong></div>
                  <div className="vendor-detail">{v.name}</div>
                  <div className="vendor-detail">{v.email}</div>
                  <div className="vendor-detail"><a href={v.website} target="_blank">{v.website_display}</a></div>
                  <div className="vendor-testimonial">
                    {
                      v.testimonials.map( (t, j) => {
                        return <div key={j} className="vendor-testimonial-item">
                          <p>
                            <i>{t.body}</i>
                          </p>
                          {
                            t.author && <span className="vendor-testimonial-author">&nbsp;-&nbsp;{t.author}, {t.city}</span>
                          }
                        </div>
                      })
                    }
                  </div>
                </div>
              })
            }
          </div>
        }
      </div>
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(VendorsRoute);