export const testAction = data => ({
  type: 'TEST_ACTION',
  data
})

export const toggleSideMenu = data => ({
  type: 'TOGGLE_SIDE_MENU',
  data
})

export const setContactModalOpen = data => ({
  type: 'SET_CONTACT_MODAL_OPEN',
  data
})


export const requestContact= credentials => {
  return {
    type: 'REQUEST_CONTACT'
  }
}

export const receiveContactSuccess = data => ({
  type: 'RECEIVE_CONTACT_SUCCESS',
  data
})

export const receiveContactFail = data => ({
  type: 'RECEIVE_CONTACT_FAIL',
  data
})

export const tryToContact= packet => dispatch => {
  dispatch(requestContact());
  
  return fetch('/api/contact', {
    method: 'post',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(packet)
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    return dispatch(receiveContactSuccess(json));
  })
  .catch(e => {
    dispatch(receiveContactFail({errors:e}));
  })
}

