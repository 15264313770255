import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './StickyTab.css';

class StickyTab extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.state = {
      top: true
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleMouseEnter(){
    this.setState({
      top: true
    })
  }

  handleMouseLeave(){
    if(window.scrollY > 0){
      this.setState({
        top: false
      });
    } else {
      this.setState({
        top: true
      });
    }
  }

  handleScroll(event) {

    if(window.scrollY > 0){
      this.setState({
        top: false
      });
    } else {
      this.setState({
        top: true
      });
    }
  }

  render(){
    const { dispatch } = this.props; 
    const state = this.state;

    return (
      <div className={"sticky-tab "
       + (state.top ? "stick-tab-top" : "sticky-tab-scrolling")}
       onMouseEnter={this.handleMouseEnter}
       onMouseLeave={this.handleMouseLeave}
       >
        <div className="flex-split">
          <div className="list-left">
            <div className="list-item brand-font logo-block">
              <span className="sticky-tab-headshot">
                <img src="/img/headshot-square.jpg" width="100px" height="100px" className="blue-logo"/>
              </span> 
              <span className="logo-wrapper">
                <img src="/img/lpp-blue-nobg-small.png"  className="blue-logo"/>
              </span> 
              <span className="sticky-tab-text">
                <strong>Lindsey Pann Paulus</strong><br/>
                <strong><a href="tel:+1-708-926-5034">708-926-5034</a></strong><br/>
                <span className="lb-email">lindsey@lindseypaulus.com</span><br/>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { globalReducer } = state;

  return {
    globalReducer
  }
}

export default connect(mapStateToProps)(StickyTab);
