import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'

// import Button from '../components/Button/Button.js'
import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ListingRow from '../components/ListingRow/ListingRow.js'

class Decluttering911 extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-box-full fa-fw secondary-header-icon"></i>&nbsp;
                Decluttering 911
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="list-left">
              <a className="list-item" href="#donations"><nobr>Dontations</nobr></a>
              <a className="list-item" href="#online"><nobr>Selling Your Stuff Online</nobr></a>
              <a className="list-item" href="#consignment"><nobr>Consignment Stores</nobr></a>
              <a className="list-item" href="#garage-sales"><nobr>Garage Sales</nobr></a>
              <a className="list-item" href="#junk-removal"><nobr>Junk Removal</nobr></a>
            </div>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>

        <div className="row row-padded" id="donations">
          <div className="col-md-12">
            <h2 className="text-lpp-primary">Donations</h2>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Goodwill <span className="text-xs"><a href="https://www.amazinggoodwill.com/" target="_blank">amazinggoodwill.com</a></span></h4>
            <p>
              Goodwill locations can be found on this site as well as acceptable donation list.
              Closest locations to La Grange area are Westchester, Willowbrook,  Downers Grove and North Riverside.
            </p>
            <p>
              They will not pick up furniture or large items from your home.  This is a 501(c)3 organization, so donations are tax deductible.
            </p>
            <blockquote>
              “Donate stuff, create jobs. When you donate your new and gently used items to Goodwill®, we sell them in our stores or on our online auction site and use the revenue generated to fund valuable employment training and job placement services for people in your community.”
            </blockquote>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Salvation Army <span className="text-xs"><a href="https://satruck.org/">satruck.org</a></span></h4>
            <p>
              The Salvation Army has donation centers (click on website to search for closest site to you).  Closest sites to La Grange Area are Darien, Oakbrook Terrace and Bridgeview.
            </p>
            <p>
              They will pick up furniture, but it is important to review your pick up over the phone to make sure it is in the condition and located within the home in a place that the service people will pick up, because they will leave without the pick up if that is not the case, which can be VERY frustrating! This is a 501(c)3 organization, so donations are tax deductible.
            </p>
            <blockquote>
              “Goods to The Salvation Army are then sold at our Family Stores. And the proceeds are used to fund our Adult Rehabilitation Centers, where those struggling with drugs and alcohol find help, hope, and a second chance at life.”
            </blockquote>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Habitat for Humanity <span className="text-xs"><a href="http://dupagerestore.com/">dupagerestore.com</a></span></h4>
            <p>
              Donations can be dropped off at the Addison Restore Tuesday through Saturday from 10 a.m. to 5:30 p.m. We offer a pick up service for select large donations. In addition to helping to reuse and recycle, your donation of building materials is tax deductible. - 501(C)(3) non-profit organization
            </p>
            <p>
              All pick-ups require the donated items to be in a garage or porch. Due to insurance and logistical reasons, drivers are not permitted to enter a residence. This includes condominiums and apartments.
            </p>
            <p>
              <a href="https://app.smartsheet.com/b/form/4c5d28613b894561ab06b7c42ae75a0d" target="_blank">ReStore filable pick up form</a>
            </p>

            <blockquote>
              “A facility dedicated to helping the environment by repurposing household items and construction materials that would otherwise be thrown out and end up in a landfill. Habitat for Humanity ReStore’s main goal is to reuse, recycle and repurpose.”
            </blockquote>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Community Nurse of La Grange <span className="text-xs"><a href="https://pillarscommunityhealth.org/community-shop/">pillarscommunityhealth.org</a></span></h4>
            <p>
              For non-furniture items such as accessories, home décor, books/media, toys – please drop off at The Community Shop Drop Off in the alley behind 23 Calendar Avenue in La Grange, Monday through Saturday, 9 am-5:30 pm. The alley is one way starting at LaGrange Rd. between Fannie Mae Candies and Chase Bank. There is a clearly marked red drop off door about three-quarters of the way down the alley. We also accept small amounts of donations in the store during our normal business hours.
            </p>
            <p>
              For gently used furniture, call 708-579-2425 to make arrangements for you to drop off, or for a FREE pick-up of pre-approved items! We will pick up from a 1st floor level, front porch or garage (unless an elevator building) items that are in very good condition.
            </p>
            <p>
              For larger estates, FREE pick-up service is available on approved estates (larger donations of furniture and non-furniture items donated together). Call 708-579-2425 to schedule pick-up. This is a 501(c)3 organization, so donations are tax deductible.
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Epilepsy Foundation <span className="text-xs"><a href="http://www.donateillinois.org/content/collectionlocations">donateillinois.org</a></span></h4>
            <p>
              Please place your donations curbside by 7:00 AM and mark them with a sheet of paper labeled "EF."  Please package donations in plastic garbage bags and tie them at the top in case of bad weather. If you place items in boxes, please be wary of rain or snow, and consider using plastic to protect the boxes. Drivers cannot collect items that are wet.
            </p>
            <ul> 
              <li>We cannot guarantee a time frame - your items will be picked up between 8 AM and the evening.</li>
              <li>Drivers are not allowed to enter homes or offices for security and insurance reasons.</li>
              <li>Businesses, Churches, and Schools must call 855-968-3662 to make special arrangements for pickup.</li>
              <li>If you have enough to fill a large box truck, please call to make special arrangements 855-968-3662.</li>
            </ul>
            <p>
              <a href="https://www.epilepsy.com/sites/core/files/atoms/files/Store-Receipt-Savers.pdf" target="_blank">Store Receipt Savers</a>
            </p>

            <blockquote>
              “Proceeds earned from donations help the Foundation provide free programs and services for nearly 140,000 Chicagoland residents living with epilepsy. You will receive a tax receipt for your donation, and help support our local cause.”
            </blockquote>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>

        <div className="row row-padded" id="online">
          <div className="col-md-12">
            <h2 className="text-lpp-primary">Selling Your Stuff Online</h2>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Ebay</h4>
            <p>
              Take photos of your item - make sure they are clear and clean photos. Create an eBay account. If you plan to sell casually, like selling items you no longer need or want, a personal account is the best option. Pick a business account if you want to sell large quantities, or have items that you've made or bought to resell. Once you've registered, here's how to start selling:
            </p>
            <ol> 
              <li>Select Sell at the top of any eBay page or go to Sell your item.</li>
              <li>Create a listing for the item you'd like to sell.</li>
              <li>Confirm your details and add an automatic payment method for any eBay fees.</li>
              <li>You may also be asked to verify your identity to keep your account secure – this could be through providing a credit card or telephone number.</li>
            </ol>
            <p>
              Ebay will require to to ship the product you are attempting to sell. Ebay does have selling fees.
            </p>
          </div>
        </div>


        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Craigslist</h4>
            <p>
              <a href="https://www.craigslist.org/about/help/how_to_post" target="_blank">How to Post</a>
            </p>
            <p>
              With Craigslist you are able to have the person pick it up from your home (usually not recommended) or you can deliver. Most often, people will meet in a public, safe place such as a police station parking lot or anywhere in public with lots of people. 
            </p>
          </div>
        </div>


        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Facebook</h4>
            <ol> 
              <li>Click the Marketplace icon, then tap the 'Sell Something' button. Take a photo of your item (you can select more than one from your gallery), and click the ‘Add Photos’ option to upload them.</li>
              <li>Enter an item title, description and price. As when selling via a group, include all relevant details to attract interest. Then set an asking price.</li>
              <li>Confirm your location. (This is how buyers browse items on Marketplace.) Don't worry, it won't reveal your exact address, only a rough location.</li>
              <li>Select a category of item. Buyers can also filter listings by these. In the final step you can also choose to post to any specific buying and selling group you're a member of at the same time as you list on Marketplace, if you want to maximise the visibility of your item.</li>
              <li>All your for-sale items will be stored under the 'Your items' tab, including any offers and messages you've received from interested buyers (see below for more on this).</li>
            </ol>
            <p>
              Whoever is purchasing your items on facebook will either pick it up or ask you to deliver. A rule of safety, like craigslist, would be to meet in a very public area if you are delivering.
            </p>
          </div>
        </div>


        <div className="row row-padded" id="consignment">
          <div className="col-md-12">
            <h2 className="text-lpp-primary">Consignment Stores</h2>
          </div>
        </div>


        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">The Courtyard - Hinsdale</h4>
            <p>
              63 Village Place<br/>
              Hinsdale IL 60521<br/>
              thecourtyard@wellnesshouse.org<br/>
              (630) 323-1135<br/>
              <a href="http://shopthecourtyard.com/consignment/" target="_blank">shopthecourtyard.com</a>
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Divine Consign - North Riverside</h4>
            <p>
              1800 S. Harlem Ave<br/>
              North Riverside, IL 60546<br/>
              (708) 386-3366<br/>
              <a href="https://www.divineconsign.com/starting-the-consignment-process/" target="_blank">divineconsign.com</a>
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Chicago Estate Sales</h4>
            <p>
              112 E. Burlington Ave<br/>
              La Grange, IL 60525<br/>
              (630) 202-3368<br/>
              resalelady@hotmail.com<br/>
              <a href="http://www.chicagoestatesales.com/" target="_blank">chicagoestatesales.com</a>
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">All-Clear Estate Sales & Home Liquidators</h4>
            <p>
              450 Northgate Ct<br/>
              Riverside, IL 60546<br/>
              (708) 870-1002<br/>
              <a href="https://www.allclearestatesales.com/" target="_blank">allclearestatesales.com</a>
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Avenue Estate Sales</h4>
            <p>
              Oakbrook<br/>
              (630) 926-6955<br/>
              <a href="http://avenueestatesales.com/" target="_blank">avenueestatesales.com</a>
            </p>
          </div>
        </div>


        <div className="row row-padded" id="garage-sales">
          <div className="col-md-12">
            <h2 className="text-lpp-primary">Garage Sales</h2>
          </div>
        </div>


        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">La Grange</h4>
            <p>
              House, Apartment, Garage, and Yard Sales: In any residential district, but only when limited to the personal possessions of the owner-occupant of the dwelling unit at which such sale is being conducted. Such use shall be limited to a period not to exceed three consecutive days, and no more than one such sale shall be conducted from the same residence in any 12-month period.
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">La Grange Park</h4>
            <p>
              <a href="https://www.lagrangepark.org/faq.aspx?qid=148" target="_blank">FAQ</a>
            </p>
            <p>
              House, apartment, garage and yard sales are allowed in any zoning district, but only when limited to personal possessions of, or arts and crafts made by, the owner or occupant of the dwelling unit where the sale is being conducted. Sales shall be limited to a period not to exceed three consecutive days and no more than three sales shall be conducted from the same residence in any 12 month period. House, apartment, garage and yard sales are exempt from obtaining a temporary use permit. Please be aware of the sign requirements regarding these sales.
            </p>
            <p>
              Temporary residential garage or yard sale signs shall be exempt from sign permit requirements, subject to the following: Temporary residential garage or yard sale signs shall not exceed 12 square feet each. Only one residential garage or yard sale sign is permitted per zoning lot. Signs must be posted no more than 24 hours prior to the event and all signs must be removed within 24 hours after the event. No signs shall be posted in the public right-of-way.
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Western Springs</h4>
            <p>
              <a href="http://wsprings.com/faq.aspx?qid=98" target="_blank">FAQ</a>
            </p>
            <p>
              No permit is necessary. Residents may hold garage sales up to four times each year. Signs for a garage sale may be erected only on the day of the sale. Signs must not exceed six square feet and 42 inches in height
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Countryside</h4>
            <p>
              <a href="https://www.countryside-il.org/faq/do-i-need-a-garage-sale-permit/" target="_blank">FAQ</a>
            </p>
            <p>
              Permits are required - garage sale permits are available at no charge from the City Clerk’s Office located at City Hall. You may hold two garage sales per year which may operate in three consecutive day increments. <a href="https://www.countryside-il.org/wp-content/uploads/2015/12/Garage-Sale-Application-2015.pdf" target="_blank">More information</a>.
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Indian Head Park</h4>
            <p>
              No permit required - no rules according to IHP Village Administration
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Burr Ridge</h4>
            <p>
              <a href="http://www.burr-ridge.gov/faqs/faqs-2/faq-regulations/" target="_blank">FAQ</a>
            </p>
            <p>
              Permits are not required to hold a garage sale and there are no regulations regarding the days a garage sale may be held. Residents may advertise the sale with signs in the public right-of-way (ROW) as long as they adhere to the Village's regulations.  Signs are only permitted between the hours of 9 AM and 6 PM, Saturdays and Sundays.  The public ROW typically extends 16 feet from the back of a curb.  For a copy of the municipal code regulations that apply to signs in the public right-of-way, visit the Document Center Chapter 55, Section 55.09.
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Brookfield</h4>
            <p>
              <a href="https://brookfieldil.gov/faq/what-are-the-rules-for-garage-sales/" target="_blank">FAQ</a>
            </p>
            <p>
              Effective January 1, 2016, a permit is no longer required for a garage sale.  However, regulations for garage sales are still in place.
            </p>
            <p>
              Garage sales are limited to three consecutive days and only two sales are allowed per person per calendar year.  Sales may only be held between the hours of 9 am and 6 pm.  Signs are permitted only on the private property at the address where the sale is being held.  Failure to comply with garage sale regulations may result in a fine of up to $25.
            </p>
          </div>
        </div>




        <div className="row row-padded" id="junk-removal">
          <div className="col-md-12">
            <h2 className="text-lpp-primary">Junk Removal</h2>
          </div>
        </div>


        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">1-800-GOT-JUNK</h4>
            <p>
              1 (800) GOT-JUNK<br/>
              <a href="https://1800gotjunk.com" target="_blank">1800gotjunk.com</a>
            </p>
          </div>
        </div>

        <div className="row row-padded">
          <div className="col-md-8">
            <h4 className="text-lpp-secondary">Metro Junk</h4>
            <p>
              (708) 280-9987<br/>
              <a href="https://metrojunkremoval.net" target="_blank">metrojunkremoval.net</a>
            </p>
          </div>
        </div>


      </div>





      
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(Decluttering911);