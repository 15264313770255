import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import shortid from 'shortid'
import { Link } from 'react-router-dom'
import $ from 'jquery'

// import Button from '../components/Button/Button.js'
import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import CustomField from '../components/CustomField/CustomField.js'
import ListingRow from '../components/ListingRow/ListingRow.js'


function cleanNumber(n){
  return Number((n + "").replace(/[^0-9.-]+/g,""));
}

const property_tax_rate = .01875;
const insurance_rate = .0042;
const pmi_rate = 0.0004167;

class NetSheetRoute extends Component {
  constructor(props){
    super(props)

    this.state = {
      selling_price: 399000,
      remaining_mortgage: 100000,
      closing_cost_credit: 0,
      estimated_equity: 0,

      brokerage_fees: 399000 * .05,
      brokerage_fee_options: [.05, .06, .07],
      brokerage_fee_selected: 0,

      title_insurance_charges: 1650,
      title_insurance_rate: .004,

      state_tax_stamps: 0,
      state_tax_rate: 1/1000,

      county_tax_stamps: 0,
      county_tax_rate: .05/1000,

      miscellaneous_fees: 125,
      brokerage_admin_fee: 350,
      attorney_fees: 600,
      survey_fee: 400,
    }

    this.updateCalculator = this.updateCalculator.bind(this);
  }

  componentDidMount(){
    this.updateCalculator(this.state);
  }

  updateCalculator(data){
    var new_state = Object.assign({}, this.state, data);


    if(data.selling_price !== new_state.selling_price_formatted){
      new_state.selling_price = cleanNumber(new_state.selling_price);
      new_state.selling_price_formatted = '$' + (new_state.selling_price).toLocaleString();
    }
    if(data.closing_cost_credit !== new_state.closing_cost_credit_formatted){
      new_state.closing_cost_credit = cleanNumber(new_state.closing_cost_credit);
      new_state.closing_cost_credit_formatted = '$' + (new_state.closing_cost_credit).toLocaleString();
    }
    
    if(data.remaining_mortgage !== new_state.remaining_mortgage_formatted){
      new_state.remaining_mortgage = cleanNumber(new_state.remaining_mortgage);
      new_state.remaining_mortgage_formatted = '$' + (new_state.remaining_mortgage).toLocaleString();
    }

    new_state.state_tax_stamps = new_state.selling_price * new_state.state_tax_rate;
    new_state.county_tax_stamps = new_state.selling_price * new_state.county_tax_rate;
    new_state.title_insurance_charges = new_state.selling_price * new_state.title_insurance_rate;
    new_state.brokerage_fees = new_state.selling_price * new_state.brokerage_fee_options[new_state.brokerage_fee_selected];

    new_state.estimated_equity = new_state.selling_price
     - new_state.remaining_mortgage
     - new_state.title_insurance_charges
     - new_state.state_tax_stamps
     - new_state.county_tax_stamps
     - new_state.miscellaneous_fees
     - new_state.brokerage_fees
     - new_state.brokerage_admin_fee
     - new_state.attorney_fees
     - new_state.survey_fee
     - new_state.closing_cost_credit;

    this.setState(new_state);
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;
    var that = this;

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-envelope-open-dollar fa-fw secondary-header-icon"></i>&nbsp;
                Net Sheet Calculator
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-padded">
          <div className="col-lg-4 col-md-4">
            
            <CustomField
              type="text"
              name="selling_price"
              placeholder="Selling Price"
              description={<span>The total amount you expect to sell your home for.</span>}
              autoUpdate={true}
              value={this.state.selling_price_formatted}
              onChangeEvent={(e) => {this.updateCalculator({selling_price: e}); }}
            />
            <br/>
            <CustomField
              type="text"
              name="remaining_mortgage"
              placeholder="Remaining Mortgage"
              description={<span>The total amount you still owe.</span>}
              autoUpdate={true}
              value={this.state.remaining_mortgage_formatted}
              onChangeEvent={(e) => {this.updateCalculator({remaining_mortgage: e}); }}
            />
           
            <br/>
            <CustomField
              type="text"
              name="closing_cost_credit"
              placeholder="Closing Cost Credit to Buyer from Seller"
              description={<span>Agreed upon amount from seller to buyer at closing.</span>}
              autoUpdate={true}
              value={this.state.closing_cost_credit_formatted}
              onChangeEvent={(e) => {this.updateCalculator({closing_cost_credit: e}); }}
            />
           
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="white-container">
              <div className="small-description">Your estimated equity:</div>
              <div className="net-sheet-equity">{'$' + Math.round(state.estimated_equity).toLocaleString() }</div>
              <table>
                <thead>
                  <tr>
                    <th>
                      Estimated Selling Costs
                    </th>
                    <th className="text-right">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="label">
                      <div className="label-text">Title Insurance Charges</div>
                      <div className="small-description">Estimated Burnet Title Rate Sheet</div>
                    </td>
                    <td className="amount">
                      { '$' + (state.title_insurance_charges).toLocaleString() }
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <div className="label-text">State Tax Stamps</div>
                      <div className="small-description">$1 per $1000 of sales price</div>
                    </td>
                    <td className="amount">
                      { '$' + (state.state_tax_stamps).toLocaleString() }
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <div className="label-text">County Tax Stamps</div>
                      <div className="small-description">$0.50 per $1000 of sales price</div>
                    </td>
                    <td className="amount">
                      { '$' + (state.county_tax_stamps).toLocaleString() }
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <div className="label-text">Miscellaneous Fees</div>
                      <div className="small-description">Wire, Policy Update, Tax Paymt, CPL, IL Policy, Releases, POA, other.</div>
                    </td>
                    <td className="amount">
                      { '$' + (state.miscellaneous_fees).toLocaleString() }
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <div className="label-text">Brokerage Fees</div>
                      <div className="small-description">Choose:&nbsp; 
                        {
                          state.brokerage_fee_options.map(function(f, i){
                            return <span>
                              <span
                                onClick={(e) => { that.updateCalculator({brokerage_fee_selected: i})}} 
                                className={"brokerage_fee_option " + (i == state.brokerage_fee_selected ? "brokerage_fee_option_selected" : "")}>
                                {Math.round(f * 100) + '%'}
                              </span>
                              {
                                i < state.brokerage_fee_options.length - 1 && <span>,&nbsp;</span>
                              }
                            </span>
                          })
                        }
                      </div>
                    </td>
                    <td className="amount">
                      { '$' + (state.brokerage_fees).toLocaleString() }
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <div className="label-text">Brokerage Admin Fee</div>
                      <div className="small-description">Flat fee portino of sales commission</div>
                    </td>
                    <td className="amount">
                      { '$' + (state.brokerage_admin_fee).toLocaleString() }
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <div className="label-text">Attorney's Fees</div>
                      <div className="small-description">Estimated</div>
                    </td>
                    <td className="amount">
                      { '$' + (state.attorney_fees).toLocaleString() }
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <div className="label-text">Plat of Survey</div>
                      <div className="small-description">Estimated</div>
                    </td>
                    <td className="amount">
                      { '$' + (state.survey_fee).toLocaleString() }
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <div className="label-text">Closing Cost Credit to Buyer from Seller</div>
                      <div className="small-description">Manually enter this using the form on the left.</div>
                    </td>
                    <td className="amount">
                      { '$' + (state.closing_cost_credit).toLocaleString() }
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr/>
              <span className="small-description text-left">
                <i>Calculator for estimation purposes only.</i>
              </span>
            </div>
            
          </div>
        </div>
        <div className="row row-padded">
          <div className="col-md-8 text-left">
            
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(NetSheetRoute);