import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid';

import './ListingThumbnail.css';

class Home extends Component {
  static propTypes = {
    // id: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    // zip: PropTypes.string,
    // price: PropTypes.number,
    // beds: PropTypes.number,
    // full_baths: PropTypes.number,
    // half_baths: PropTypes.number,
    // sqft: PropTypes.number,
    // img_label: PropTypes.string
  };

  static defaultProps = {
    id: "12345",
    address: '1234 Main St',
    address2: '',
    city: 'La Grange',
    state: 'IL',
    zip: '60513',
    price: 649000,
    // beds: false,
    // full_baths: false,
    // half_baths: false,
    // sqft: false
    img_label: ""
  }

  constructor(props){
    super(props);
  }

  render(){
    const { user, dispatch } = this.props;

    const id = 'carousel-' + shortid.generate();

    return <div className="listing-thumbnail">
      <Link to={"/listing/" + this.props.id}>
        <div className="listing-thumbnail-image" style={{
            backgroundImage: 'url(' + this.props.img_url + ')',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>

          {
            this.props.isMyListing &&
            <div className="corner-ribbon top-right">
              LPP Listing
            </div>
          }

          {
            this.props.img_label &&
            <span className={"listing-thumbnail-image-label " + (this.props.status === "SOLD" ? "listing-thumbnail-image-label-secondary" :"")}>{this.props.img_label}</span>
          }
          {
            this.props.carousel &&
            <div id={id} className="carousel slide" data-interval="false">
              <ol className="carousel-indicators">
                {
                  this.props.carousel.map((img_url, i)=>{
                    return <li data-target={"#" + id} data-slide-to={i} className={i == 0 ? "active" : ""} key={i}>
                      <i className="fas fa-circle fa-fw"/>
                    </li>
                  })
                }
              </ol>
              <div className="carousel-inner">
                {
                  this.props.carousel.map((img_url, i)=>{
                    return <div className={"carousel-item " + (i == 0 ? "active" : "")} key={i}>
                      <Link to={"/listing/" + this.props.id}>
                        <img src={img_url} width="100%"/>
                      </Link>
                    </div>
                  })
                }
              </div>
              <a className="carousel-control-prev" href={"#" + id} role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href={"#" + id} role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          }
        </div>
      </Link>
      <Link className="listing-thumbnail-info" to={"/listing/" + this.props.id}>
        <div className="bt-split">
          <div className="bt-left">
            <div className="bt-price">{'$' + parseInt(this.props.price).toLocaleString()}</div>
          </div>
          <div className="bt-right">
          {
            this.props.status && 
            <div className={"bt-status " + (this.props.status === "SOLD" ? "bt-status-secondary" : "bt-status-primary")}>{this.props.status === 'Contingent' ? 'Under Contract' : this.props.status}</div>
          }
          </div>
        </div>
        <div className="bt-split">
          <div className="bt-left">
            <div className="bt-bed-bath list-left">
              {
                this.props.beds && 
                <span className="list-item">
                  <strong>{this.props.beds}</strong> beds 
                </span>
              }
              {
                (this.props.full_baths || this.props.half_baths) && 
                <span className="list-item">
                  <strong>{this.props.full_baths}.{this.props.half_baths}</strong> baths 
                </span>
              }
              {
                this.props.sqft && 
                <span className="list-item">
                  <strong>{this.props.sqft}</strong> sqft
                </span>
              }
            </div>

            <div className="bt-address">
              {this.props.address} {this.props.address2}<br/>
              {this.props.city}, {this.props.state}
            </div>
          </div>
        </div>
      </Link>
    </div>
  }

  compnentWillReceiveProps(newProps){
    
  }   
}


const mapStateToProps = (state) => {
  const { 
    //...
  } = state;

  return {
    //...
  }
}

export default connect(mapStateToProps)(Home);