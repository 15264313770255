import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid';

import './NeighborhoodPuck.css';

class NeighborhoodPuck extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    price: PropTypes.number,
    beds: PropTypes.number,
    bath: PropTypes.number,
    sqft: PropTypes.number
  };

  static defaultProps = {
    neighborhood: 'Country Club',
    city: 'La Grange',
    url: 'country-club'
  }

  constructor(props){
    super(props);
  }

  render(){
    const { dispatch } = this.props;

    return <Link className="neighborhood-puck" to={"/neighborhoods/" + this.props.url}>
      <div className="neighborhood-puck-explore">
        <span className="text-lg">
          {this.props.neighborhood}
        </span>
        <span>Learn More</span>
      </div>
      <div className="neighborhood-puck-image" style={{
        backgroundImage: 'url(img/' + this.props.img + '.jpg)'
      }}>
      </div>
      <div className="neighborhood-puck-text">
        <strong className="text-lpp-primary">{this.props.neighborhood}</strong>
        <small>{this.props.city}</small>
      </div>
      <div className="neighborhood-puck-arrow">
        <i className="far fa-angle-right fa-lg"/>
      </div>
    </Link>
  }

  compnentWillReceiveProps(newProps){
    
  }   
}


const mapStateToProps = (state) => {
  const { 
    nav
    //...
  } = state;

  return {
    nav
    //...
  }
}

export default connect(mapStateToProps)(NeighborhoodPuck);