export const globalReducer = (state = {
  sidemenu: {
    open: false
  },
  contactModal: {
    open: false
  },
  contactForm: {
    name: '',
    email: '',
    phone: '',
    message: ''
  }
}, action) => {

  switch(action.type){
    case 'TEST_ACTION':

      return {
        ...state
      }

    case 'TOGGLE_SIDE_MENU': 

      if(action.data !== undefined){
        return {
          ...state, 
          sidemenu: {
            open: action.data
          }
        }
      } else {
        return {
          ...state,
          sidemenu: {
            open: !state.sidemenu.open
          }
        }
      }


    case 'SET_CONTACT_MODAL_OPEN': 

      if(action.data !== undefined){
        return {
          ...state, 
          contactModal: {
            open: action.data,
            listing_id: action.data.listing_id
          },
          contactForm: {
            message: action.data.message
          },
          successfulContact: false
        }
      } else {
        return {
          ...state,
          contactModal: {
            open: !state.sidemenu.open
          },
          successfulContact: false
        }
      }


    case 'REQUEST_CONTACT':

      return {
        ...state,
        tryingToContact: true,
        successfulContact: false
      }

    case 'RECEIVE_CONTACT_FAIL':

      return {
        ...state,
        tryingToContact: false,
        successfulContact: false
      }

    case 'RECEIVE_CONTACT_SUCCESS':

      return {
        ...state,
        tryingToContact: false,
        successfulContact: new Date().getTime(),
        contactModal: {
          open: false,
        },
        contactForm: {
          name: '',
          email: '',
          phone: '',
          message: ''
        }
      }
      
    default:
      return state
  }
}