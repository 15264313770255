 import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'

// import Button from '../components/Button/Button.js'
import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ListingRow from '../components/ListingRow/ListingRow.js'

class ClientResourcesRoute extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-user-alt fa-fw secondary-header-icon"></i>&nbsp;
                Client Resources
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-padded justify-content-center">
          <div className="col-md-3 text-center">
            <div className="white-container">
              <div className="col-icon">
                <span className="fa-stack fa-3x text-lpp-secondary">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fas fa-tools fa-stack-1x fa-inverse"></i>
                </span>
              </div>
              <h4 className="">Recommended Vendors</h4>
              <p className="push-down">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in diam nec risus dictum accumsan. In nibh nulla, laoreet faucibus lectus id, iaculis venenatis ante.</p>
              <Link to="/client-resources/recommended-vendors"><Button color="primary" block={true} display="Get trustworthy help"/></Link>
            </div>
          </div>

          <div className="col-md-3 text-center">
            <div className="white-container">
              <div className="col-icon">
                <span className="fa-stack fa-3x text-lpp-secondary">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fas fa-box-full fa-stack-1x fa-inverse"></i>
                </span>
              </div>
              <h4 className="">Decluttering 911</h4>
              <p className="push-down">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in diam nec risus dictum accumsan. In nibh nulla, laoreet faucibus lectus id, iaculis venenatis ante.</p>
              <Link to="/client-resources/decluttering-911"><Button color="primary" display="Get organized"/></Link>
            </div>
          </div>

          <div className="col-md-3 text-center">
            <div className="white-container">
              <div className="col-icon">
                <span className="fa-stack fa-3x text-lpp-secondary">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fas fa-file-invoice-dollar fa-stack-1x fa-inverse"></i>
                </span>
              </div>
              <h4 className="">Mortgage Calculator</h4>
              <p className="push-down">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in diam nec risus dictum accumsan. In nibh nulla, laoreet faucibus lectus id, iaculis venenatis ante.</p>
              <Link to="/client-resources/mortgage-calculator"><Button color="primary" display="Plan your budget"/></Link>
            </div>
          </div>

          <div className="col-md-3 text-center">
            <div className="white-container">
              <div className="col-icon">
                <span className="fa-stack fa-3x text-lpp-secondary">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fas fa-envelope-open-dollar fa-stack-1x fa-inverse"></i>
                </span>
              </div>

              <h4 className="">Net Sheet Calculator</h4>
              <p className="push-down">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in diam nec risus dictum accumsan. In nibh nulla, laoreet faucibus lectus id, iaculis venenatis ante.</p>
              <Link to="/client-resources/net-sheet-calculator"><Button color="primary" display="See your equity"/></Link>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(ClientResourcesRoute);