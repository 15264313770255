import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'


import './Footer.css';

class Footer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
  }

  render(){
    const { dispatch, user } = this.props; 
    const state = this.state;

    return (
      <div className="footer">
        <div className="container">

          <div className="row justify-content-center row-padded">
            <div className="col-md-5 text-center">
              <h4 className=""><nobr>Lindsey Pann Paulus</nobr></h4>

              <div className="list-center">
                <small className="list-item">
                  <a href="mailto:lindsey@lindseypaulus.com">lindsey@lindseypaulus.com</a>
                </small>
                <small className="list-item">
                  <nobr><a href="tel:+17089265034" className="text-heavy">708-926-5034</a></nobr>
                </small>
                <nobr>
                  <a href="https://www.facebook.com/lindseypaulusrealestate/" target="_blank"><i className="fab fa-facebook fa-fw fa-2x"/></a>
                  <a href="https://www.linkedin.com/in/lindsey-paulus-b63aa952" target="_blank"><i className="fab fa-linkedin fa-fw fa-2x"/></a>
                </nobr>
              
              </div>
              <br/>
              <div className="list-center">
                <img src="/img/logo-lockup-vertical.png" height="150px" className="list-item"/>
              </div>
              <br/>
              <p><small className="text-muted"><em>All data sourced from MLS.</em></small></p>
              <div className="list-center">
                <img src="/img/realtor-white.png" height="60px" className="list-item"/>
                <img src="/img/equal-housing-white.png" height="67px" className="list-item"/>
               </div>
            </div>
          </div>
        </div>
        
        <div className="super-footer">
          <nobr>@properties</nobr> | <nobr>4472 Lawn Ave</nobr> <nobr>Western Springs, IL 60558 </nobr>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  // const { user } = state;

  return {
    // user
  }
}

export default connect(mapStateToProps)(Footer);
