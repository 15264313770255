import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import Autosuggest from 'react-autosuggest';
import Select from 'react-select'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import CustomField from '../components/CustomField/CustomField.js'
import ListingRow from '../components/ListingRow/ListingRow.js'
import ListingThumbnail from '../components/ListingThumbnail/ListingThumbnail.js'

import { tryToFetchSearchListings } from '../actions/actions.export.js'

const google = window.google;

var map;
var infowindow;
var bounds;
var markers;

const cities = require('../ihf-cities.json');

const propertyTypes = [
  {
    value: "SFR,CDN",
    label: "Single Family  & Condos", 
  },
  {
    value: "SFR",
    label: "Single Family Residences", 
  },
  {
    value: "CND",
    label: "Condos", 
  },
  {
    value: "FRM",
    label: "Farms", 
  },
  {
    value: "RI",
    label: "Residential Income", 
  },
  {
    value: "MH",
    label: "Mobile Homes", 
  },
  {
    value: "LL",
    label: "Lots and Land", 
  },
  {
    value: "RNT",
    label: "Rentals", 
  },
  {
    value: "COM",
    label: "Commercial"
  }
]

var bedroomOptions = [
  {
    value: 0,
    label: "Any"
  },
  {
    value: 1,
    label: "1+"
  },
  {
    value: 2,
    label: "2+"
  },
  {
    value: 3,
    label: "3+"
  },
  {
    value: 4,
    label: "4+"
  },
  {
    value: 5,
    label: "5+"
  }
]

var bathroomOptions = [
  {
    value: 0,
    label: "Any"
  },
  {
    value: 1,
    label: "1+"
  },
  {
    value: 2,
    label: "2+"
  },
  {
    value: 3,
    label: "3+"
  },
  {
    value: 4,
    label: "4+"
  },
  {
    value: 5,
    label: "5+"
  }
]

var priceOptions = [
  {
    value: '',
    label: "Any"
  },
  {
    value: '50,000',
    label: "$50k"
  },
  {
    value: '75,000',
    label: "$75k"
  },
  {
    value: '100,000',
    label: "$100k"
  },
  {
    value: '150,000',
    label: "$150k"
  },
  {
    value: '200,000',
    label: "$200k"
  },
  {
    value: '250,000',
    label: "$250k"
  },
  {
    value: '300,000',
    label: "$300k"
  },
  {
    value: '350,000',
    label: "$350k"
  },
  {
    value: '400,000',
    label: "$400k"
  },
  {
    value: '450,000',
    label: "$450k"
  },
  {
    value: '500,000',
    label: "$500k"
  },
  {
    value: '550,000',
    label: "$550k"
  },
  {
    value: '600,000',
    label: "$600k"
  },
  {
    value: '650,000',
    label: "$650k"
  },
  {
    value: '700,000',
    label: "$700k"
  },
  {
    value: '750,000',
    label: "$750k"
  },
  {
    value: '800,000',
    label: "$800k"
  },
  {
    value: '850,000',
    label: "$850k"
  },
  {
    value: '900,000',
    label: "$900k"
  },
  {
    value: '950,000',
    label: "$950k"
  },
  {
    value: '1,000,000',
    label: "$1M"
  },
  {
    value: '1,250,000',
    label: "$1.25M"
  },
  {
    value: '1,500,000',
    label: "$1.5M"
  },
  {
    value: '1,750,000',
    label: "$1.75M"
  },
  {
    value: '1,000,000',
    label: "$1M"
  },
  {
    value: '1,250,000',
    label: "$1.25M"
  },
  {
    value: '1,500,000',
    label: "$1.5M"
  },
  {
    value: '1,750,000',
    label: "$1.75M"
  },
  {
    value: '2,000,000',
    label: "$2M"
  },
  {
    value: '2,250,000',
    label: "$2.25M"
  },
  {
    value: '2,500,000',
    label: "$2.5M"
  },
  {
    value: '2,750,000',
    label: "$2.75M"
  },
  {
    value: '3,000,000',
    label: "$3M"
  },
  {
    value: '3,500,000',
    label: "$3.5M"
  },
  {
    value: '4,000,000',
    label: "$4M"
  },
  {
    value: '4,500,000',
    label: "$4.5M"
  },
  {
    value: '5,000,000',
    label: "$5M"
  },
  {
    value: '6,000,000',
    label: "$6M"
  },
  {
    value: '7,000,000',
    label: "$7M"
  },
  {
    value: '8,000,000',
    label: "$8M"
  },
  {
    value: '9,000,000',
    label: "$9M"
  },
  {
    value: '10,000,000',
    label: "$10M"
  }
]

class SearchRoute extends Component {
  constructor(props){
    super(props)

    this.state = {
      cityId: '3924',
      pg: 0,
      propertyType: 'SFR,CDN',
      bedrooms: 0,
      bathCount: 0,
      minListPrice: '',
      maxListPrice: ''
    }

    this.onCityChange = this.onCityChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onBedroomChange = this.onBedroomChange.bind(this);
    this.onBathroomChange = this.onBathroomChange.bind(this);

    this.onMinPriceChange = this.onMinPriceChange.bind(this);
    this.onMaxPriceChange = this.onMaxPriceChange.bind(this);
    
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
  }

  onCityChange(e){
    const { dispatch } = this.props;

    this.setState({
      cityId: e.value
    })

    dispatch(tryToFetchSearchListings({
      cityId: e.value,
      propertyType: this.state.propertyType,
      pg: this.state.pg,
      bedrooms: this.state.bedrooms,
      bathCount: this.state.bathCount,
      maxListPrice: this.state.maxListPrice,
      minListPrice: this.state.minListPrice
    }))
  };

  onTypeChange(e){
    const { dispatch } = this.props;

    this.setState({
      propertyType: e.value
    }) 

    dispatch(tryToFetchSearchListings({
      cityId: this.state.cityId,
      propertyType: e.value,
      pg: this.state.pg,
      bedrooms: this.state.bedrooms,
      bathCount: this.state.bathCount,
      maxListPrice: this.state.maxListPrice,
      minListPrice: this.state.minListPrice
    }))
  };

  onBedroomChange(e){
    const { dispatch } = this.props;

    this.setState({
      bedrooms: e.value
    })

    dispatch(tryToFetchSearchListings({
      cityId: this.state.cityId,
      propertyType: this.state.propertyType,
      pg: this.state.pg,
      bedrooms: e.value,
      bathCount: this.state.bathCount,
      maxListPrice: this.state.maxListPrice,
      minListPrice: this.state.minListPrice
    }))
  };


  onBathroomChange(e){
    const { dispatch } = this.props;

    this.setState({
      bathCount: e.value
    })

    dispatch(tryToFetchSearchListings({
      cityId: this.state.cityId,
      propertyType: this.state.propertyType,
      pg: this.state.pg,
      bedrooms: this.state.bedrooms,
      bathCount: e.value,
      maxListPrice: this.state.maxListPrice,
      minListPrice: this.state.minListPrice
    }))
  };


  onMinPriceChange(e){
    const { dispatch } = this.props;

    this.setState({
      minListPrice: e.value
    })

    dispatch(tryToFetchSearchListings({
      cityId: this.state.cityId,
      propertyType: this.state.propertyType,
      pg: this.state.pg,
      bedrooms: this.state.bedrooms,
      bathCount: this.state.bathCount,
      maxListPrice: this.state.maxListPrice,
      minListPrice: e.value
    }))
  };

  onMaxPriceChange(e){
    const { dispatch } = this.props;

    this.setState({
      maxListPrice: e.value
    })

    dispatch(tryToFetchSearchListings({
      cityId: this.state.cityId,
      propertyType: this.state.propertyType,
      pg: this.state.pg,
      bedrooms: this.state.bedrooms,
      bathCount: this.state.bathCount,
      maxListPrice: e.value,
      minListPrice: this.state.minListPrice
    }))
  };


  handleNextPage = () => {
    const { dispatch } = this.props;
    

    var pg = this.state.pg + 1;

    dispatch(tryToFetchSearchListings({
      cityId: this.state.cityId,
      propertyType: this.state.propertyType,
      pg: pg,
      bedrooms: this.state.bedrooms,
      bathCount: this.state.bathCount,
      maxListPrice: this.state.maxListPrice,
      minListPrice: this.state.minListPrice
    }))

    this.setState({
      pg: pg
    })
  }

  handlePreviousPage = () => {
    const { dispatch } = this.props;

    var pg = this.state.pg - 1;
    if(pg < 1) pg = 1;
    
    
    dispatch(tryToFetchSearchListings({
      cityId: this.state.cityId,
      propertyType: this.state.propertyType,
      pg: pg,
      bedrooms: this.state.bedrooms,
      bathCount: this.state.bathCount,
      maxListPrice: this.state.maxListPrice,
      minListPrice: this.state.minListPrice
    }))

    this.setState({
      pg: pg
    })
  }

  componentDidMount(){
    const { dispatch, realtor } = this.props;

    // create map
    map = new google.maps.Map(document.getElementById('map'), {zoom: 13, center: {lat: 41.8054577, lng: -87.8748644}});
    // create empty info window for maps
    infowindow = new google.maps.InfoWindow();

    // grab and format the city name from the URL (if one exists)
    var city = this.state.cityId;
    var hash = window.location.hash.replace('#','');

    if(hash){
      var pieces = hash.split('-');
      for(var i in pieces){
        pieces[i] = pieces[i][0].toUpperCase() + pieces[i].substring(1, pieces[i].length);
      }
      city = pieces.join(' ');

      for(var i in cities){
        if(cities[i].label == city){
          this.setState({
            cityId: cities[i].value
          })
        }  
      }
    }
    
    // try to prevent redundant loading
    if(realtor.listingSearchResults.length == 0 || hash){
      setTimeout((e)=>{
        // lag this in case city is set by the hash
        dispatch(tryToFetchSearchListings({
          cityId: this.state.cityId,
          propertyType: this.state.propertyType,
          pg: this.state.pg,
          bedrooms: this.state.bedrooms,
          bathCount: this.state.bathCount,
          maxListPrice: this.state.maxListPrice,
          minListPrice: this.state.minListPrice
        }))
      }, 100);
    } else {
      for(var i in realtor.listingSearchResults){
        var l = realtor.listingSearchResults[i];
        this.addMarker(l, i)
      }
    }
  }

  componentDidUpdate(){    

    function createMarker(place) {
      var marker = new google.maps.Marker({
        map: map,
        position: place.geometry.location
      });

      google.maps.event.addListener(marker, 'click', function() {
        infowindow.setContent('\
          <a href="https://www.google.com/maps/search/?api=1&query=Google&query_place_id=' + place.place_id + '" target="_blank">\
            <strong>' + place.name + '</strong><br/>\
            <small>Click to learn more.</small>\
          </a>');
        infowindow.open(map, this);
      });
    }
  }

  componentWillReceiveProps(newprops){
    for(var i in markers){
      markers[i].setMap(null);
    }
    for(var i in newprops.realtor.listingSearchResults){
      var l = newprops.realtor.listingSearchResults[i];
      this.addMarker(l, i)
    }
  }

  addMarker(l, index){
    var cleanAddress = l.address.split('#')[0]; // remove unit to prevent # from breaking our URL format

    var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
    url += ' ' + cleanAddress + ', ' + l.city +', ' + l.state;
    url += '&key=' + 'AIzaSyAGIY_qZJYgJVIFmjnmJDwbBGxMPEEpT94';
    
    bounds  = new google.maps.LatLngBounds();

    fetch(url, {
        method: 'get'
      })
      .then((response) => {
        let json = response.json();
        if (response.status >= 200 && response.status < 300) return json;
        return json.then(Promise.reject.bind(Promise));
      })
      .then(function(json){
        if(!json.results) return;
        
        var marker = new google.maps.Marker({
          map: map,
          label: {
            text: 'abcdefghijklmnopqrstuvwxyz'.charAt(parseInt(index)).toUpperCase(),
            color: 'white',
          },
          position: json.results[0].geometry.location
        });

        var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
        bounds.extend(loc);
        map.fitBounds(bounds);
        map.panToBounds(bounds);

        if(!markers) markers = [];
        markers.push(marker);

        google.maps.event.addListener(marker, 'click', function() {
          infowindow.setContent('\
            <a href="/listing/' + l.id + '" class="map-thumbnail">\
              <img class="map-thumbnail-img" src="' + l.photo + '" width="150px"/><br/><br/>\
              <span class="map-thumbnail-address">' + l.address + '</span>\
            </a>');
          infowindow.open(map, this);
        });
      })
  }

  render(){
    const { dispatch, realtor } = this.props;
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Search by city or neighborhood...',
      value,
      onChange: this.onChange
    };


    var page_from = (parseInt(realtor.listingSearchPage) - 1) * 24 + 1;
    var page_to = (parseInt(realtor.listingSearchPage)) * 24;
    if(page_to > parseInt(realtor.listingSearchResultsTotal)){
      page_to = parseInt(realtor.listingSearchResultsTotal);
    }

    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header secondary-header-small-margin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-search fa-fw secondary-header-icon"></i>&nbsp;
                MLS Search
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row search-row">
          <div className="col-md-3">
            <span className="search-label">City</span>
            <Select 
              options={cities} 
              defaultValue={cities.filter(city => city.value === this.state.cityId)}
              value={cities.filter(city => city.value === this.state.cityId)}
              onChange={this.onCityChange}
              />
          </div>
          <div className="col-md-3">
            <span className="search-label">Property Type</span>
            <Select 
              options={propertyTypes}
              defaultValue={propertyTypes.filter(type => type.value === this.state.propertyType)}
              onChange={this.onTypeChange}
              />
          </div>

          <div className="col-md-1">
            <span className="search-label">Bedrooms</span>
            <Select 
              options={bedroomOptions}
              defaultValue={bedroomOptions.filter(e => e.value === this.state.bedrooms)}
              onChange={this.onBedroomChange}
              />
          </div>
          <div className="col-md-1">
            <span className="search-label">Bathrooms</span>
            <Select 
              options={bathroomOptions}
              defaultValue={bathroomOptions.filter(e => e.value === this.state.bathCount)}
              onChange={this.onBathroomChange}
              />
          </div>
          <div className="col-md-2">
            <span className="search-label">Min Price</span>
            <Select 
              options={priceOptions}
              defaultValue={priceOptions.filter(e => e.value === this.state.minListPrice)}
              onChange={this.onMinPriceChange}
              />
          </div>
          <div className="col-md-2">
            <span className="search-label">Max Price</span>
            <Select 
              options={priceOptions}
              defaultValue={priceOptions.filter(e => e.value === this.state.maxListPrice)}
              onChange={this.onMaxPriceChange}
              />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
             <div id="map" className="map"></div>
          </div>
          <div className="col-md-6">
            {
              (realtor.listingSearchResultsTotal === 'No' && !realtor.tryingToGetSearchListings) && 
              <div className="search-results-header">
                <span className="text-lpp-primary">
                  <i className="fas fa-exclamation-triangle"/> No listings found. Your search filters may be too narrow.
                </span>
              </div>
            }
            {
              (!realtor.tryingToGetSearchListings && realtor.listingSearchResultsTotal !== 'No') &&
              <div className="search-results-header">
                <span>
                  Showing { page_from }-{ page_to } of { realtor.listingSearchResultsTotal } listings
                </span>
                <span>
                  Page { realtor.listingSearchPage } of { realtor.listingSearchPages } &nbsp;&nbsp;
                  <span onClick={this.handlePreviousPage} className={"a-fake " + (realtor.listingSearchPage == "1" ? "disabled" : "")}>
                    <i className="far fa-angle-left fa-lg fa-fw"/>
                    Previous
                  </span>
                  &nbsp;&nbsp;
                  <span onClick={this.handleNextPage} className={"a-fake " + (realtor.listingSearchPage === realtor.listingSearchPages ? "disabled" : "")}>
                    Next
                    <i className="far fa-angle-right fa-lg fa-fw"/>
                  </span>
                </span>
              </div>
            }
            <div className="overflow-y-scroll-listings shortened">
              {
                realtor.tryingToGetSearchListings && <p className="lead translucent">
                  <i className="fal fa-spinner-third fa-spin fa-fw"/> Loading...
                  <br/>
                </p>
              }
              {
                !realtor.tryingToGetSearchListings &&
                <div className="row">
                  {
                    realtor.listingSearchResults.map((l, i) => {
                      return <div className="col-md-6" key={i}>
                        <ListingThumbnail
                          id={l.id}
                          type={l.type}
                          img_url={l.photo}
                          address={l.address}
                          city={l.city}
                          state={l.state}
                          zip={l.zip}
                          beds={l.beds}
                          full_baths={l.full_baths}
                          half_baths={l.half_baths}
                          sqft={l.sqft}
                          price={l.price}
                          status={l.status}
                          isMyListing={realtor.myListingIDs.indexOf(l.id) > -1}
                          img_label={'abcdefghijklmnopqrstuvwxyz'.charAt(parseInt(i)).toUpperCase()}
                        />
                      </div>
                    })
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    realtor
    // ...
  } = state;

  return {
    realtor
    // ...
  }
}

export default connect(mapStateToProps)(SearchRoute);