import React, { Component } from 'react'
import { push } from 'react-router-redux'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import shortid from 'shortid'
import moment from 'moment'

import Header from '../components/Header/Header.js'
import StickyTab from '../components/StickyTab/StickyTab.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import NeighborhoodPuck from '../components/NeighborhoodPuck/NeighborhoodPuck.js'
import CustomField from '../components/CustomField/CustomField.js'
import ListingRow from '../components/ListingRow/ListingRow.js'
import ListingThumbnail from '../components/ListingThumbnail/ListingThumbnail.js'
import Review from '../components/Review/Review.js'
import ReviewCarousel from '../components/ReviewCarousel/ReviewCarousel.js'
import ContactForm from '../components/ContactForm/ContactForm.js'

import { setContactModalOpen } from '../actions/actions.export.js'

const carousel_listing_id = 'carousel-' + shortid.generate();
const carousel_script_id = 'carousel-' + shortid.generate();

class Home extends Component {
  constructor(props){
    super(props)

    this.state = {
      landing_carousel_current: 0,
      landing_carousel_images: ['img/home_1_small.jpg', 'img/home_2_small.jpg', 'img/home_3_small.jpg', 'img/home_4_small.jpg'],
      script_carousel_current: 0,
      script_carousel_lap: 0,
      script_carousel_images: ['img/welcome.svg'] //, 'img/Asset-3.svg', 'img/Asset-2.svg', 'img/share.svg'],
    }

    this.animateBackgroundCarousel = this.animateBackgroundCarousel.bind(this);
    this.animateScriptCarousel = this.animateScriptCarousel.bind(this);
  }

  componentDidMount(){
    this.animateBackgroundCarousel();
    this.animateScriptCarousel();
    this.tryToInitiateListingCarousel();
  }

  tryToInitiateListingCarousel(){
    // console.log('try to initiate carousel');
    var el = document.getElementById(carousel_listing_id);

    if(el){
      // console.log('found carousel, initiating');
      $('#' + carousel_listing_id).carousel({
        interval: 5000,
        ride: "carousel"
      });
    } else {
      // console.log('can\'t find carousel, trying again in one second');
      setTimeout((e)=>{
        this.tryToInitiateListingCarousel();
      }, 1000);
    }
  }

  animateBackgroundCarousel(){
    const carousel_size = this.state.landing_carousel_images.length;
    var next_carousel = this.state.landing_carousel_current + 1;

    if(next_carousel >= carousel_size) next_carousel = 0;

    setTimeout(e => {
      this.setState({
        landing_carousel_current: next_carousel
      })
      this.animateBackgroundCarousel();
    }, 10000)
  }

  animateScriptCarousel(){
    const carousel_size = this.state.script_carousel_images.length;
    var next_carousel = this.state.script_carousel_current + 1;
    var lap = this.state.script_carousel_lap;
    if(next_carousel >= carousel_size){
      next_carousel = 0;
      lap++;
    }
  }

  render(){
    const { dispatch, realtor } = this.props;
    const state = this.state;

    var random_home = 'home_' + parseInt( Math.random() * 4 + 1, 10); 


    var l = realtor.listings[0];

    let limited_listings = JSON.parse(JSON.stringify(realtor.listings));
    limited_listings = limited_listings.filter(l => l.status !== "SOLD" || l.price >= 450000);
    limited_listings = limited_listings.splice(0,10);

    return <div className="landing">

      <Header overImage={true}/>
      
      <div className="jumbotron jumbotron-secondary jumbotron-mega-pad-bottom">
        <div className="jumbo-bg">
          {
            this.state.landing_carousel_images.map(function(c, i){
              return <div className={"bg-carousel " + (i === state.landing_carousel_current ? "bg-carousel-current" : "")} 
                style={{
                  backgroundImage: 'url(' + c + ')'
                }} 
                key={i}
                >
                  <div className="bottom-right-label">

                  </div>
                </div>
            })
          }
          <div className="hero-image-overlay"/>
        </div>
        
        <div className="jumbo-content">
          <div className="container">
            {
              moment().isBefore('2023-02-04') ?
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <div className="interrupt">

                    <div className="interrupt-button">
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSdpWFuiPLCD5eYYk-CaGeLpr88UMydyNOwTpUJQMl31oF1Q3g/viewform?usp=sf_link" className="callout-btn" target="_blank">
                        <span className="callout-btn-inner">
                          Click Here to Take the Quiz!
                        </span>
                      </a>
                    </div>
                    <div className="interrupt-text">
                      4 Correct Answers gives you the opportunity to win Dinner at the Elm and movie tickets for 2 at The La Grange Theater.
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="header-bumper-small"/>
            }
            <div className="row">
              <div className="col-md-8">
                <div className="list-left block-on-small list-left-align-top">
                  <img src="/img/headshot-2-square.png" className="home-avatar list-item"/>
                  <div className="list-item">
                    <div><h1 style={{paddingTop: 20}}>Lindsey <nobr>Pann Paulus</nobr></h1></div>
                    <div><h3>lindsey@lindseypaulus.com</h3></div>
                    <div><h3>708-926-5034</h3></div>
                    <img src="/img/logo-lockup-horizontal.png" height="45px" className="list-item"/>
                    <div className="list-left block-on-small" style={{fontSize: 18, marginTop: 8, paddingTop: 4 }}>
                      Real Estate in Chicago's Western Suburbs
                    </div>
                  </div>
                </div>
              </div>
              {
                l && 
                <div className="col-lg-4 col-gutter d-none d-xl-block">
                  <div className="white-container" style={{position: 'absolute', width: '100%'}}>
                    <div className="corner-ribbon top-right" style={{zIndex: 5}}>LPP Listing</div>
                      <div id={carousel_listing_id} className="carousel carousel-auto slide" data-interval={5000} data-ride="carousel">
                        <ol className="carousel-indicators">
                          {
                            limited_listings.map((img_url, i)=>{
                              return <li data-target={"#" + carousel_listing_id} data-slide-to={i} className={i == 0 ? "active" : ""} key={i}>
                                <i className="fas fa-circle fa-fw"/>
                              </li>
                            })
                          }
                        </ol>
                        <div className="carousel-inner">
                          {
                            limited_listings.map((l, i)=>{
                              return <div className={"carousel-item " + (i == 0 ? "active" : "")} key={i}>
                                <ListingThumbnail
                                  id={l.id}
                                  type={l.type}
                                  img_url={l.photo}
                                  address={l.address}
                                  city={l.city}
                                  state={l.state}
                                  zip={l.zip}
                                  beds={l.beds}
                                  full_baths={l.full_baths}
                                  half_baths={l.half_baths}
                                  sqft={l.sqft}
                                  price={l.price}
                                  status={l.status}
                                />
                              </div>
                            })
                          }
                        </div>
                        <a className="carousel-control-prev" href={"#" + carousel_listing_id} role="button" data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href={"#" + carousel_listing_id} role="button" data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    {/*<Link to="/listings">
                      <Button display="See all LPP Listings" color="primary"/>
                    </Link>*/}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        
      </div> 
      <div className="bg-secondary">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 text-center hero-writing-parent">
              <img src={this.state.script_carousel_images[this.state.script_carousel_current] + '?' + this.state.script_carousel_lap} className="hero-writing"/>
              {/*<img src="/resources/here-to-help-you-01-2_animated.svg" className="hero-writing"/>*/}
            </div>
          </div>
        </div>
      </div>
      

      <div className="page-section-mega-pad" id="listings">
        <div className="container">
          
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <h3 className="">Welcome to the Neighborhood</h3>
            </div>
          </div>
          <div className="row row-padded justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <NeighborhoodPuck neighborhood="La Grange" img="home_2_small" city="Illinois" url="la-grange"/>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <NeighborhoodPuck neighborhood="Western Springs" img="home_3_small" city="Illinois" url="western-springs"/>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <NeighborhoodPuck neighborhood="La Grange Park" img="home_4_small" city="Illinois" url="la-grange-park"/>
            </div>
          </div>
          <div className="row row-padded justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <NeighborhoodPuck neighborhood="Hinsdale" img="Hinsdale" city="Illinois" url="hinsdale"/>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <NeighborhoodPuck neighborhood="Indian Head Park" img="Indian-Head-Park" city="Illinois" url="indian-head-park"/>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <NeighborhoodPuck neighborhood="Brookfield" img="Brookfield" city="Illinois" url="brookfield"/>
            </div>
          </div>
        
        </div>
        {
          realtor.listings.length > 0
          &&
          <div>
          <hr className="mega"/>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                  <h3 className="">LPP Listings</h3>
                </div>
              </div>
            </div>
            <ListingRow/>
          </div>
        }
      </div>
    

      <div className="screen-height-container shc-secondary shc-padded" id="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center">Testimonials</h1>
              <p className="lead text-center text-heavy">Here's what my clients had to say:</p>
            </div>
          </div>
          <div className="row row-push-down display-flex justify-content-center"> 

            <div className="col-lg-5 col-gutter">
              <div className="white-container">
                <div className="list-left">
                  <div className="list-item review-avatar" style={{backgroundImage: 'url(/img/testimonials/Travis-and-Brittany.jpg)'}}></div>
                  <div className="list-item">
                    <strong>Travis and Brittany</strong> <small>2022 buyers</small><br/>
                    {/*<span>Western Springs, IL</span><br/>*/}
                    <small className="text-yellow">
                      <i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/>
                    </small>
                  </div>
                </div>
                <hr/>
                <p>
                  As first time home buyers, our ignorance could have led us into a poor decision, but Lindsey was open, honest & applied our feedback while creating a strategy that was competitive in a seller's market. We needed someone who was knowledgeable, fast and had strong business connections, Lindsey was that agent. Lindsey was everything we could have hoped for in an agent from day one to closing. 
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-gutter">
              <div className="white-container">
                <div className="list-left">
                  <div className="list-item review-avatar" style={{backgroundImage: 'url(/img/testimonials/Nick-Ellis.jpg)'}}></div>
                  <div className="list-item">
                    <strong>Nick Ellis</strong> <small>2020 seller & 2022 buyer</small><br/>
                    {/*<span>Western Springs, IL</span><br/>*/}
                    <small className="text-yellow">
                      <i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/>
                    </small>
                  </div>
                </div>
                <hr/>
                <p>
                  Lindsey has represented me twice. As a buyer, Lindsey was invaluable during the negotiation process. Lindsey coached us on the best way to present an offer, respond to the counter, & finalize the deal. As a seller, Lindsey ensured the preparation for the sale was done promptly & correctly. During our abbreviated close, everything was completed on time without incident. I've bought & sold several homes, & have never worked with an agent as exceptional as Lindsey.
                </p>
              </div>
            </div>


            <div className="col-lg-5 col-gutter">
              <div className="white-container">
                <div className="list-left">
                  <div className="list-item review-avatar" style={{backgroundImage: 'url(/img/testimonials/Sandy-Morgan.jpg)'}}></div>
                  <div className="list-item">
                    <strong>Sandy Morgan</strong> <small>2022 buyer and seller</small><br/>
                    {/*<span>Western Springs, IL</span><br/>*/}
                    <small className="text-yellow">
                      <i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/>
                    </small>
                  </div>
                </div>
                <hr/>
                <p>
                  Lindsey has represented me as a buyer & seller. In under two months, I bought a house, sold a house, and moved into my new home. Lindsey is attentive, professional, resourceful, has a wonderful personality & is always ready to answer any questions. She is highly recommended by this very satisfied client.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-gutter">
              <div className="white-container">
                <div className="list-left">
                  <div className="list-item review-avatar" style={{backgroundImage: 'url(/img/testimonials/carolyn-s.jpg)'}}></div>
                  <div className="list-item">
                    <strong>Carolyn Schmidt</strong> <small>2022 seller</small><br/>
                    {/*<span>Western Springs, IL</span><br/>*/}
                    <small className="text-yellow">
                      <i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/>
                    </small>
                  </div>
                </div>
                <hr/>
                <p>
                  Lindsey is a rock star! With a tight schedule, many moving pieces, a crazy market, and a couple of toddlers in the mix, she was able to walk us through every step of the selling process with empathy, patience, humanity and knowledge. She managed our expectations, advised us on so many decisions, and set us up for success. It was ultimately a seamless transaction and Lindsey was the best choice we could have made!
                </p>
              </div>
            </div>

          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <a href="https://www.zillow.com/profile/Lindsey-Paulus/#reviews" target="_blank">
                <Button color="white" display={<span>135+ more reviews on Zillow&nbsp;&nbsp;<i className="fas fa-lg fa-angle-right"/></span>} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="screen-height-container shc-padded">
        <div className="container">
          
            <div className="row row-padded justify-content-center">
              
              <div className="col-md-8">  
                <h3 className="text-lpp-primary" style={{marginBottom: 20}} id="about">About Me</h3>
                <div className="d-lg-none">
                  <img src="/img/headshot-2.jpg" width="100%" className="rounded-image"/><br/><br/>
                </div>
                <div>
                  <div className="inblock-image d-none d-lg-block ">
                    <img src="/img/headshot-2.jpg" width="350px" className="rounded-image"/><br/><br/>
                  </div>
                  <p>As a realtor, I believe what really sets me apart is the personal relationships I develop with my clients. So, as we begin this journey, here’s a bit about me.</p>
                  <p>The Western Suburbs of Chicago are my original stomping grounds. I grew up in Western Springs and loved everything about it. Its quaint size, friendly people and proximity to everything. Back then, the “uptown” was even smaller and I remember walking to Showtime Video, spending summers at “the pool” and running in the Tower Trot. During this time, downtown La Grange wasn’t nearly as cool, but was quickly turning into the bustling town of today—full of eclectic restaurants and shops.</p>
                  <p>In the ‘90s I graduated from Lyons Township High School and headed off to study business at the University of Illinois, Champaign/Urbana. After graduating, life was a whirlwind. I lived in Chicago for a few years, moved to my parent’s new home in LaGrange, started my own clothing business and met my husband, Dave.</p>
                  <p>Long story short, I put my career on hold to raise our three boys who are now teenagers…until a good friend of mine, Sara Bass, told me her mom wanted help with her real estate business. Sara’s mom had already guided Dave and I through three home purchases and I had enjoyed every minute of it. I decided to go for it. </p>
                  <p>That was 2012. Each year I have grown more in love with helping people buy and sell homes. It’s emotional, exciting, fun and never dull because I wear so many different hats. I get to be creative…the one to tell it like it is…the cheerleader…the unlicensed therapist…the hard-ass…the comedian…and the one who puts it all into perspective. I work really hard and it pays off with happy clients and great relationships that have helped my business grow. </p>
                  <p>I would love to help you buy or sell a house. I promise to listen and work nonstop to help you reach your goals. I will always show up with a smile and bring positive energy to every step of the process. I’m excited to get to know you, earn your trust and help you during one of the biggest moments of a lifetime.  </p>
                </div>
                <img src="/img/signature.png" width="250px" style={{marginTop: 20}}/>
              </div>
            </div>
          
        </div>
      </div>

      <div className="screen-height-container shc-secondary shc-padded flex-center-column" id="contact">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <h2>Contact Me!</h2>
              <p className="lead">Let's get in touch.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="white-container">
                <ContactForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    realtor
    // ...
  } = state;

  return {
    realtor
    // ...
  }
}

export default connect(mapStateToProps)(Home);