import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import BlogPost from '../components/BlogPost/BlogPost.js'
import BlogSideWidgets from '../components/BlogSideWidgets/BlogSideWidgets.js'
import ListingRow from '../components/ListingRow/ListingRow.js'

import { 
  tryToFetchBlogPost
} from '../actions/actions.export.js'

class BlogPostRoute extends Component {
  constructor(props){
    super(props)
  }

  update(newprops){

    const { dispatch} = this.props;
    const blog = newprops.blog;

    // console.log('update', blog.tryToFetchBlogPost, blog.currentPosts, newprops.match.params.id);
    if(blog.tryingToFetchBlogPost || blog.postError) return;

    if(blog.currentPosts.length === 1 && blog.currentPosts[0] == newprops.match.params.id) return;
      
    dispatch(tryToFetchBlogPost({
      id: newprops.match.params.id
    }));
  }

  componentWillReceiveProps(newprops){
    this.update(newprops);
  }

  componentDidMount(){
    this.update(this.props);
  }

  render(){
    const { dispatch, blog } = this.props;
    const state = this.state;


    return <div className="landing">
      <Header scrolling={true} overImage={false}/>
      <div className="header-bumper-small"/>
      <div className="secondary-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="">
                <i className="fas fa-blog fa-fw secondary-header-icon"></i>&nbsp;
                Blog
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7">
            {
              (blog.currentPosts.length == 1 && !blog.tryingToFetchBlogPost) ?
              <BlogPost post={blog.postCache[blog.currentPosts[0]]}/>
              :
              <BlogPost/>
            }
          </div>
          <div className="col-md-3">
            <BlogSideWidgets/>
          </div>
        </div>
      </div>

      
      <div className="container">
        <div className="row row-padded">
          <div className="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <ListingRow/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    blog
    // ...
  } = state;

  return {
    blog
    // ...
  }
}

export default connect(mapStateToProps)(BlogPostRoute);