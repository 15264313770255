import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid'
import jquery from 'jquery'

import Button from '../Button/Button.js'

import './ReviewCarousel.css';

var rotateInterval;

class ReviewCarousel extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {
   
  }

  constructor(props){
    super(props);

    this.state = {
      currentIndex: 3
    }

    this.handleForwards = this.handleForwards.bind(this);
    this.handleBackwards = this.handleBackwards.bind(this);
    this.autoRotate = this.autoRotate.bind(this);
  }

  handleForwards(){
    var index = this.state.currentIndex + 1;

    if(index >= this.props.reviews.length){
      index = 0;
    }
    console.log(index);

    this.setState({
      currentIndex: index
    })
  }

  handleBackwards(){
    var index = this.state.currentIndex - 1;

    if(index < 0){
      index = this.props.reviews.length - 1;
    }

    this.setState({
      currentIndex: index
    })
  }

  autoRotate(){
    rotateInterval = setInterval((e)=>{
      this.handleForwards();
    }, 5000);
  }


  render(){
    const { dispatch } = this.props;
    const state = this.state;

    var currentReview = this.props.reviews[this.state.currentIndex];

    return <div className="review-carousel">
      
      <div id="carouselExampleIndicators" className="carousel review-carousel-jq slide" data-ride="carousel">
        <div className="carousel-inner">
          {
            this.props.reviews.map(function(r, i){
              return <div className={
                  "carousel-item "
                   + (i === state.currentIndex ? "active" : "")
                 } key={i}>
                <div className="container">
                  <div className="row row-padded justify-content-center">
                    <div className="col-md-8">
                      { r }
                    </div>
                  </div>
                </div>
              </div>
            })
          }
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
        

        <div className="shc-bottom-content">
          <div className="container">
            <div className="row row-push-down justify-content-center">
              <div className="col-md-8">
                <ol className="carousel-indicators">
                  {
                    this.props.reviews.map(function(r,i){
                      return <li data-target="#carouselExampleIndicators" data-slide-to={i} key={i} className="active">
                        <i className="fas fa-circle fa-fw"/>
                      </li>
                    })
                  }
                </ol>
              </div>
            </div>
            <div className="row row-push-down">
              <div className="col-md-12">
                <div className="list-center">
                  <a href="https://www.zillow.com/profile/Lindsey-Paulus/#reviews" target="_blank"><Button display={<span>75+ more testimonials on Zillow&nbsp;&nbsp;<i className="fas fa-lg fa-angle-right"/></span>} /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  compnentWillReceiveProps(newProps){
    
  }   
}


const mapStateToProps = (state) => {
  const { 
    //...
  } = state;

  return {
    //...
  }
}

export default connect(mapStateToProps)(ReviewCarousel);